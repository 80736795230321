import React, { useState } from "react";
import UsageBar from "@components/Profile/UsageBar";
import ManageSubscriptionButton from "@components/Profile/ManageSubscriptionButton";
import { useApi } from "@api/api";
import { CgMathPlus } from "react-icons/cg";

const formatDateWithDays = (dateString) => {
  const today = new Date();
  const endDate = new Date(dateString);
  endDate.setHours(0, 0, 0, 0); // Truncate time to start of the day

  const daysLeft = Math.round(
    (Number(endDate) - Number(today)) / (1000 * 60 * 60 * 24),
  );
  const formattedDate = endDate.toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return `${formattedDate} (${daysLeft} days)`;
};
const SubscriptionInfo: React.FC<{
  kind: string;
  subscription_end: string;
  tokens: number;
  used_tokens: number;
}> = ({ kind, subscription_end, tokens, used_tokens }) => {
  const [code, setCode] = useState("");
  const [editingCode, setEditingCode] = useState(false);
  const { redeemCode } = useApi();

  return (
    <div className="">
      <div className="mb-4">
        <div className="flex justify-between">
          <div>
            <p className="font-bold">Subscription Type:</p>
            <p>
              {kind === "trial"
                ? "Trial"
                : kind === "month_to_month"
                  ? "Month-to-Month"
                  : "Annual"}
            </p>
          </div>
          <div>
            <ManageSubscriptionButton />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="mb-4">
          <p className="font-bold">
            {kind === "trial" ? "Subscription Ends:" : "Renews on:"}
          </p>
          <p>{formatDateWithDays(subscription_end)}</p>
        </div>
        {kind === "trial" && (
          <div className="btn" onClick={() => setEditingCode((p) => !p)}>
            Redeem Code
          </div>
        )}
      </div>

      {editingCode && (
        <div className="flex mb-4 join justify-center">
          <input
            className="input input-bordered w-full join-item"
            placeholder="Enter your code here"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <div
            className="btn join-item text-2xl"
            onClick={() => {
              setEditingCode(false);
              if (code === "") return;
              redeemCode.mutate(code);
              setCode("");
            }}
          >
            <CgMathPlus />
          </div>
        </div>
      )}

      {kind === "trial" && (
        <>
          <UsageBar used={used_tokens} limit={tokens} />
          <div>
            <p>
              ~{Math.round(used_tokens / 1000)} out of{" "}
              {Math.round(tokens / 1000)} recipes used
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionInfo;
