import React, { useState } from "react";
import { useApi } from "@api/api";

const TrialExpiredView: React.FC = () => {
  const [plan, setPlan] = useState<"month_to_month" | "annual">("annual");
  const { createCheckoutSession } = useApi();

  const handleOrder = async () => {
    try {
      const { sessionUrl } = await createCheckoutSession(plan);
      window.location.assign(sessionUrl);
    } catch (error) {
      console.error("Error creating checkout session: ", error);
    }
  };

  const monthlyCost = 9;
  const annualCost = 5;
  const annualSavings = ((monthlyCost - annualCost) * 12).toFixed(0);
  const annualSavingsPercentage = (
    (1 - annualCost / monthlyCost) *
    100
  ).toFixed(0);

  return (
    <div className="flex flex-col items-center justify-center h-screen p-4">
      <div className="text-center">
        <h2 className="text-3xl font-bold mb-3">Your Trial Has Ended</h2>
        <p className="text-sm mb-2">
          Thanks for trying Mealbot! To continue enjoying our services, please
          select a subscription plan.
        </p>

        <p className="text-lg mb-5">
          Choose a plan to keep cooking with Mealbot:
        </p>
        <div className="flex flex-col items-center space-y-4">
          <button
            onClick={() => setPlan("month_to_month")}
            className={`btn btn-lg ${
              plan === "month_to_month" ? "btn-accent" : "btn-outline"
            } mb-2`}
          >
            Monthly - $8.99/mo
          </button>
          <button
            onClick={() => setPlan("annual")}
            className={`btn btn-lg ${
              plan === "annual" ? "btn-accent" : "btn-outline"
            }`}
          >
            Annual - $4.99/mo
          </button>
          {plan === "month_to_month" && (
            <div className="text-sm">
              <p>Switch to Annual and save ${annualSavings} a year</p>
              <p>That's a {annualSavingsPercentage}% reduction!</p>
            </div>
          )}
        </div>
        <button onClick={handleOrder} className="btn btn-primary btn-lg mt-8">
          Continue with Mealbot
        </button>
        <p className="text-lg mt-6 mb-8">
          Unlock unlimited recipes and meal plans.
        </p>
        <p>Join us and enhance your cooking experience!</p>
      </div>
    </div>
  );
};

export default TrialExpiredView;
