import React from "react";
import { capitalizeFirstLetter } from "@components/util";

type DietOptionsProps = {
  selectedDiet: string;
  setSelectedDiet: React.Dispatch<React.SetStateAction<string>>;
  size?: "small" | "large";
};

const DietOptions: React.FC<DietOptionsProps> = ({
  selectedDiet,
  setSelectedDiet,
  size = "large",
}) => {
  const diets: string[] = [
    "anything",
    "vegetarian",
    "vegan",
    "pescatarian",
    "paleo",
    "keto",
    "kosher",
  ];

  const dietEmojis: { [key: string]: string } = {
    anything: "🍔",
    vegetarian: "🥦",
    vegan: "🌱",
    pescatarian: "🐟",
    paleo: "🍖",
    keto: "🧀",
    kosher: "✡️",
  };

  const handleSelect = (diet: string) => {
    setSelectedDiet(diet);
  };

  const sizeClasses =
    size === "small"
      ? "btn-sm text-lg font-light"
      : "btn-lg text-lg text-medium";

  return (
    <div className="flex flex-wrap gap-2 items-center justify-center">
      {diets.map((diet) => (
        <button
          key={diet}
          className={`btn ${sizeClasses} ${
            selectedDiet === diet ? "btn-primary" : ""
          }`}
          onClick={() => handleSelect(diet)}
        >
          {dietEmojis[diet]} {capitalizeFirstLetter(diet)}
        </button>
      ))}
    </div>
  );
};

export default DietOptions;
