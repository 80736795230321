import { useContext } from "react";
import { AuthContext, AuthContextValue } from "@contexts/AuthContext";

export default function useAuth(): AuthContextValue {
  const context = useContext(AuthContext) as AuthContextValue;
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}
