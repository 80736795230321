import React, { useState } from "react";
import IconCounter from "@components/IconCounter";
import { CgUser } from "react-icons/cg";
import { Divider } from "react-daisyui";
import { Ingredient, Macros, Recipe } from "@api/api";
import InstructionList from "@components/InstructionList";
import { MdCheckCircle, MdOutlineRadioButtonUnchecked } from "react-icons/md";
import ConfettiExplosion from "react-confetti-explosion";

type RecipeProps = { recipe: Recipe };

const RecipeView: React.FC<RecipeProps> = ({ recipe }) => {
  if (!recipe) return null;
  console.log("recipe", recipe);
  return (
    <>
      <div>{recipe.name || "No Name"}</div>
      <Divider />
      <Servings servings={recipe.servings} />
      <CookingTime cookingTime={recipe.cooking_time} />
      {recipe.shopping_list && recipe.shopping_list.length > 0 && (
        <>
          <Divider />
          <div className="flex items-center mb-2">
            <div className="font-bold">Shopping List:</div>
          </div>
          <div className="ml-4">
            <ShoppingList items={recipe.shopping_list} />
          </div>
        </>
      )}
      <Divider />
      <Ingredients ingredients={recipe.ingredients} />
      <Divider />
      <Instructions instructions={recipe.instructions} />
      <Divider />
      <MacrosView macros={recipe.macros} />
      <Divider />
      <div className="mt-8 text-xs">
        All recipes and nutritional facts provided are generated and should be
        used as guidelines only. Actual nutritional values may vary based on the
        specific ingredients used.
      </div>
    </>
  );
};

export default RecipeView;

const Ingredients: React.FC<{ ingredients: Ingredient[] }> = ({
  ingredients,
}) => {
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());

  const toggleItem = (index: number) => {
    setSelectedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  return (
    <div>
      <div className="flex items-center mb-2">
        <div className="font-bold">Ingredients:</div>
      </div>
      {ingredients.map((ingredient, index) => (
        <IngredientView
          {...ingredient}
          key={index}
          selected={selectedItems.has(index)}
          onClick={() => toggleItem(index)}
        />
      ))}
    </div>
  );
};
interface Ingredient2 {
  name: string;
  description: string;
  quantity: string;
  selected?: boolean;
  onClick?: () => void;
}

const IngredientView: React.FC<Ingredient2> = ({
  name,
  description,
  quantity,
  selected,
  onClick,
}) => {
  const lineClass = selected ? "line-through" : "";
  return (
    <div className="ml-4 w-full flex" onClick={onClick}>
      <div className="w-2/12">
        <CircleCheckbox checked={selected} />
      </div>
      <div className={"w-5/12 font-bold " + lineClass}>{name}</div>
      <div className={"items text-sm flex w-5/12 font-light ml-2 " + lineClass}>
        {quantity}, {description}
      </div>
    </div>
  );
};

const Servings: React.FC<{ servings: number }> = ({ servings }) => {
  return (
    <div className="flex items-center m-2">
      <div className="font-bold mr-6 w-3/12">Servings:</div>
      <div className="flex items-center w-9/12">
        <IconCounter count={servings} Icon={CgUser} />
      </div>
    </div>
  );
};

const CookingTime: React.FC<{ cookingTime: string }> = ({ cookingTime }) => {
  return (
    <div className="flex items-center m-2">
      <div className="font-bold mr-6 w-3/12">Cooking Time:</div>
      <div className="flex items-center w-9/12">{cookingTime}</div>
    </div>
  );
};

interface InstructionStep {
  step: string;
  duration: string;
}

const Instructions: React.FC<{ instructions: string | InstructionStep[] }> = ({
  instructions,
}) => {
  let instructionLines: React.JSX.Element | null = null;

  if (typeof instructions === "string") {
    instructionLines = (
      <>
        {instructions.split("\n").map((line, index) => (
          <div key={index} className="whitespace-normal break-words m-4">
            {line}
          </div>
        ))}
      </>
    );
  } else if (Array.isArray(instructions)) {
    instructionLines = <InstructionList instructions={instructions} />;
  }

  return (
    <div>
      <div className="flex items-center mb-2">
        <div className="font-bold">Instructions:</div>
      </div>
      <div className="ml-4">{instructionLines}</div>
    </div>
  );
};

const MacrosView: React.FC<{ macros: Macros }> = ({ macros }) => {
  return (
    <>
      <div className="mb-2 font-bold">Nutritional Facts:</div>
      <div className="ml-8">
        <div className="flex mb-2">
          <div className="font-bold w-7/12">Estimated calories/serving:</div>
          <div className="w-5/12">{macros.calories_per_serving} cal</div>
        </div>
        <div className="flex">
          <div className="font-bold w-7/12">Carbs:</div>
          <div className="w-5/12">{macros.carbs}</div>
        </div>
        <div className="flex">
          <div className="font-bold w-7/12">Fats:</div>
          <div className="w-5/12">{macros.fats}</div>
        </div>
        <div className="flex">
          <div className="font-bold w-7/12">Proteins:</div>
          <div className="w-5/12">{macros.proteins}</div>
        </div>
      </div>
    </>
  );
};

const ShoppingList: React.FC<{ items?: string[] }> = ({ items }) => {
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());

  const isExploding = selectedItems.size === (items?.length || -1);
  if (!items) return null;
  const handleCheckboxChange = (instructionId: number) => {
    setSelectedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(instructionId)) {
        newSet.delete(instructionId);
      } else {
        newSet.add(instructionId);
      }
      return newSet;
    });
  };

  return (
    <>
      {isExploding && (
        <ConfettiExplosion
          force={0.8}
          duration={3000}
          particleCount={300}
          width={1000}
        />
      )}
      {items.map((item, index) => (
        <div
          key={index}
          className="flex w-full items-center"
          onClick={() => handleCheckboxChange(index)}
        >
          <div className="w-2/12">
            <CircleCheckbox checked={selectedItems.has(index)} />
          </div>
          <div
            className={
              "w-10/12 " + (selectedItems.has(index) ? "line-through" : "")
            }
          >
            <span className="">{item}</span>
          </div>
        </div>
      ))}
    </>
  );
};

const CircleCheckbox: React.FC<{ checked: boolean }> = ({ checked }) => {
  if (checked) {
    return <MdCheckCircle size={30} color="green" />;
  }
  return <MdOutlineRadioButtonUnchecked size={30} />;
};
