import React from "react";
import { Progress } from "react-daisyui";
import { ComponentColor } from "react-daisyui/dist/types";

interface UsageProps {
  used: number;
  limit: number;
}

const UsageBar: React.FC<UsageProps> = ({ used, limit }) => {
  const percentageUsed = used / limit;
  let color: ComponentColor = "info";

  if (percentageUsed >= 0.7) {
    color = "warning";
  }
  if (percentageUsed >= 0.95) {
    color = "error";
  }

  return <Progress color={color} value={percentageUsed} />;
};

export default UsageBar;
