import React from "react";

const RateLimitView: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen p-4 text-xl">
      <div className="text-center">
        <h2 className="text-3xl font-bold mb-3">Whoa, Slow Down!</h2>
        <p className="mb-2">
          It looks like you've been cooking up a storm with Mealbot!
        </p>
        <p>
          🕒 So we can control costs, there's a limit of requests per hour and a
          total per day.
        </p>
        <p className="text-lg mb-5">
          We love your enthusiasm, but there's a limit to how many recipes you
          can request. Please take a moment to digest the recipes you've already
          got!
        </p>
        <div className="mb-5">
          <p>
            ⏳ Check back in an hour to continue exploring delicious recipes.
          </p>
        </div>
        <p className="text-lg">Thanks for understanding and happy cooking!</p>
      </div>
    </div>
  );
};

export default RateLimitView;
