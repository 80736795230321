export const sortData = (data, keysString, direction) => {
    const primaryKeys = keysString.split(',').map(key => key.trim().split('.'));
    const sortedData = [...data];

    sortedData.sort((a, b) => {
        for (const keys of primaryKeys) {
            let aValue = a;
            let bValue = b;

            // Navigate through the nested properties
            for (const k of keys) {
                aValue = aValue[k];
                bValue = bValue[k];
            }

            // Compare the values
            if (aValue < bValue) {
                return direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return direction === 'asc' ? 1 : -1;
            }
            // If values are equal, continue to the next set of keys
        }

        // All keys have equal values
        return 0;
    });

    return sortedData;
};



  
export const options = { 
    usersSortBy: {
      value: "last_interaction",
      options: ["role", "subscription.kind", "token_count", "last_interaction"]
    },
    maxUsers: {
      value: 10,
      options: [10, 25, 50, 100, 1000],
    },
    feedbackSortBy: {
        value: "timestamp",
        options: ["timestamp", "rating", "user_id", "feedback"]
    },
    maxFeedback: {
        value: 10,
        options: [10, 25, 50, 100, 1000],
    },
    includeArchived: {
        value: "false",
        options: ["true", "false"],
    },
    sentiment: {
        value: "all",
        options: ["all", "thumbs_up", "thumbs_down"],
    },
    search: {
        value: "",
        options: [],
    },
    promotionCodeSortBy: {
        value: "created",
        options: ["code", "discount", "expires", "created"]
    },
    maxPromotionCodes: {
        value: 10,
        options: [10, 25, 50, 100, 1000],
    },
}