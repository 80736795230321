import { createContext, ReactNode, useMemo, useState } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import { getAPIURL } from "@api/apiUrl";
import toast from "react-hot-toast";

const decodeToken = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(window.atob(base64));
};
const apiUrl = getAPIURL();

async function formPostRequest(
  url: string,
  body: Record<string, any>,
): Promise<unknown> {
  const formData = new URLSearchParams();
  for (const [key, value] of Object.entries(body)) {
    formData.append(key, value.toString());
  }

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const response = await fetch(apiUrl + url, {
    method: "POST",
    headers,
    body: formData,
  });
  if (!response.ok) throw new Error("Network response was not ok");
  return await response.json();
}

async function jsonPostRequest(
  url: string,
  body: Record<string, any>,
): Promise<unknown> {
  const response = await fetch(apiUrl + url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    const error = new Error("Network response was not ok");
    error.cause = response; // Attaching the response as a cause
    throw error;
  }

  return await response.json();
}

async function postRequest(
  url: string,
  body: Record<string, any>,
  token?: string,
): Promise<Response> {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  if (token) headers["Authorization"] = `Bearer ${token}`;
  return fetch(apiUrl + url, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  });
}

export interface TokenData {
  accessToken: string;
  accessTokenExpires: string;
  refreshToken: string;
  refreshTokenExpires: string;
}

export const AuthContext = createContext({});

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
  const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", null);
  const [currentUser, setCurrentUser] = useLocalStorage("currentUser", null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const handleAuthenticationResponse = (tokenResponse: TokenData) => {
    setAccessToken(tokenResponse.accessToken);
    setRefreshToken(tokenResponse.refreshToken);

    const decodedToken = decodeToken(tokenResponse.accessToken);
    setCurrentUser(decodedToken.sub);
  };

  const login = (username, password) => {
    setIsLoggingIn(true);

    return formPostRequest("login", { username, password })
      .then((response: TokenData) => {
        handleAuthenticationResponse(response);
      })
      .catch((error) => {
        console.error("Authentication failed:", error);
        logout();
        throw error;
      })
      .finally(() => {
        setIsLoggingIn(false);
      });
  };
  const googleLogin = (token: string) => {
    setIsLoggingIn(true);
    return jsonPostRequest("google_login", { token })
      .then((response: TokenData) => {
        handleAuthenticationResponse(response);
      })
      .catch((error) => {
        console.error("Authentication failed:", error);
        logout();
        throw error;
      })
      .finally(() => {
        setIsLoggingIn(false);
      });
  };
  const socialLogin = async ({ provider, data }) => {
    setIsLoggingIn(true);
    console.log(provider, data)
    try {
      try {
        const referral_code = localStorage.getItem("ref");
        const response = (await jsonPostRequest("social_login", {
          provider,
          data,
          referral_code,
        })) as TokenData;
        handleAuthenticationResponse(response);
      } catch (error) {
        console.error("Authentication failed:", error);

        if (
          error.cause &&
          error.cause.status === 403 &&
          error.cause.headers.get("content-type")?.includes("application/json")
        ) {
          error.cause
            .json()
            .then((json: { detail: string }) => {
              toast(
                (t) => (
                  <span>
                    {json.detail || "Access denied."}{" "}
                    <a
                      href="https://mealbot.app"
                      className="text-blue-500 hover:underline font-bold m-1"
                    >
                      Go to Production
                    </a>
                    <button
                      className={"btn btn-sm text-red-500"}
                      onClick={() => toast.dismiss(t.id)}
                    >
                      X
                    </button>
                  </span>
                ),
                { duration: Infinity },
              );
            })
            .catch(() => {
              toast.error("Error: Failed to parse error details.");
            });
        } else {
          toast.error("Authentication failed.");
        }
        logout();
        throw error;
      }
    } finally {
      setIsLoggingIn(false);
    }
  };

  const refresh = async () => {
    try {
      const response = (await jsonPostRequest("refresh", {
        refreshToken,
      })) as TokenData;
      handleAuthenticationResponse(response);
      return response;
    } catch (error) {
      console.error("Refresh failed:", error);
      logout();
      throw error;
    }
  };

  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
    setCurrentUser(null);
  };

  async function authPost(url: string, body?: any) {
    try {
      const response = await postRequest(url, body, accessToken);
      if (!response.ok) {
        if (response.status === 401) {
          const tokenData = await refresh();
          if (!tokenData) return;
          const response1 = await postRequest(url, body, tokenData.accessToken);
          if (!response1.ok) throw new Error("Network response was not ok");
          return await response1.json();
        } else if (response.status === 403) {
          throw new Error(await response.text());
        } else if (response.status === 402) {
          return {
            error: "Trial or subscription expired",
            msg: (await response.json())["detail"],
            status: 402,
          };
        } else if (response.status === 429) {
          return {
            error: "Too many requests",
            msg: (await response.json())["detail"],
            status: 429,
          };
        } else {
          throw new Error("Network response was not ok");
        }
      }

      return await response.json();
    } catch (error) {
      console.error("Request failed:", error);
      if (error.response && error.response.status === 401) {
        logout();
      }
      throw error;
    }
  }

  const isLoggedIn = !!accessToken;

  const memoedValue = useMemo(
    () => ({
      login,
      googleLogin,
      socialLogin,
      refresh,
      logout,
      authPost,
      currentUser,
      isLoggedIn,
      isLoggingIn,
    }),
    [currentUser, isLoggedIn, accessToken],
  );
  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

export interface AuthContextValue {
  login: (username: string, password: string) => Promise<void>;
  googleLogin: (token: string) => Promise<void>;
  socialLogin: ({ provider, data }) => Promise<void>;
  refresh: () => Promise<TokenData>;
  logout: () => void;
  authPost: (url: string, body?: any) => Promise<any>;
  currentUser: string | null;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
}
