import { gtag } from "ga-gtag";
export const isLocalhost = window.location.hostname === "localhost";
export const isDev = window.location.hostname === "dev.mealbot.app";
export const needsGoogleAnalytics = !isLocalhost && !isDev;
export const sentryEnvName = isLocalhost ? "local" : isDev ? "dev" : "prod";
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function tagEvent(event: string, extra?: any): void {
  if (!needsGoogleAnalytics) return;
  gtag("event", event, extra || {});
}
