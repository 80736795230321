declare global {
  interface Window {
    _env_: {
      MICROSOFT_CLIENT_ID: string;
      GOOGLE_CLIENT_ID: string;
      FACEBOOK_APP_ID: string;
      API_URL: string;
    };
  }
}

export const getAPIURL = (): string => {
  const trimmedUrl = trimPathAfterFirstSlash(window._env_.API_URL);
  return ensureTrailingSlash(trimmedUrl);
};

const findFirstSlashAfterHttp = (url: string): number => {
  return url.indexOf("/", 8); // 8 is the length of 'https://'
};

const extractPath = (url: string, domainEndIndex: number): string => {
  return url.substring(domainEndIndex + 1);
};

const trimPathAfterFirstSlash = (url: string): string => {
  const domainEndIndex = findFirstSlashAfterHttp(url);
  const path = extractPath(url, domainEndIndex);

  const firstSlashInPath = path.indexOf("/");

  if (firstSlashInPath !== -1) {
    return url.substring(0, domainEndIndex + firstSlashInPath + 1);
  } else if (domainEndIndex !== -1) {
    return url.substring(0, domainEndIndex + 1);
  }
  return url;
};

const ensureTrailingSlash = (url: string): string => {
  if (url.lastIndexOf("/") !== url.length - 1) {
    return url + "/";
  }
  return url;
};
