import React, { ReactElement } from "react";

// Define the type for the props
interface AvatarProps {
  icon: ReactElement;
}

const Avatar: React.FC<AvatarProps> = ({ icon }) => {
  return (
      <div className="flex bg-neutral-content w-12 h-12 items-center justify-center rounded-full text-2xl">
        {icon}
      </div>
  );
};

export default Avatar;
