import { useEffect, useState, useMemo } from "react";
import { useAdminApi } from "@api/adminApi";
import ShortLoading from "@components/ShortLoading";
import { Table } from "react-daisyui";
import SortableOption from "@components/Admin/SortableOption";
import { sortData, options } from "@components/Admin/util";

const UserList = () => {
  const [sortConfig, setSortConfig] = useState({
    key: "last_interaction",
    direction: "desc",
  });
  const [filters, setFilters] = useState(options);

  const { useUserListQuery, useAdminMetadataQuery, changeUserRole } =
    useAdminApi();
  const userListQuery = useUserListQuery(filters.maxUsers.value);
  const { data: users, isLoading } = userListQuery;
  const { data: metadata } = useAdminMetadataQuery();
  const { roles: roleOptions } = metadata || {};

  const sortedAndFilteredUsers = useMemo(() => {
    const sortedUsers = sortData(
      users || [],
      sortConfig.key,
      sortConfig.direction,
    );
    return sortedUsers.slice(0, filters.maxUsers.value);
  }, [users, sortConfig, filters.maxUsers.value]);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      usersSortBy: {
        ...prevFilters.usersSortBy,
        value: prevFilters.usersSortBy.value,
      },
    }));
  }, [users, filters.usersSortBy.value]);

  const handleSortChange = (key, direction) => {
    setSortConfig({ key, direction });
  };

  const changeFilter = (filterName, newValue) => {
    setFilters({
      ...filters,
      [filterName]: { ...filters[filterName], value: newValue },
    });
  };

  if (isLoading) return <ShortLoading />;

  return (
    <>
      <div className="flex items-center align-middle">
        <div className="mr-2 flex items-center align-middle">
          <label className="label flex items-center align-middle">
            <div className="m-2">Max Users</div>
          </label>
          <select
            className="select select-bordered w-28 select-sm"
            onChange={(e) => changeFilter("maxUsers", parseInt(e.target.value))}
            value={filters.maxUsers.value}
          >
            <option value={1}>1</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={1000}>1000</option>
          </select>
        </div>
      </div>
      <hr className="my-4" />
      <Table>
        <Table.Head>
          <SortableOption
            title="Email"
            id="email"
            onSortChange={handleSortChange}
            activeSortKey={sortConfig.key}
          />
          <SortableOption
            title="Role"
            id="role,email,last_interaction"
            onSortChange={handleSortChange}
            activeSortKey={sortConfig.key}
          />
          <SortableOption
            title="Subscription"
            id="subscription.kind,email,last_interaction"
            onSortChange={handleSortChange}
            activeSortKey={sortConfig.key}
          />
          <SortableOption
            title="Token Usage (last 24h)"
            id="daily_ai_request_count"
            onSortChange={handleSortChange}
            activeSortKey={sortConfig.key}
          />
          <SortableOption
            title="Last Interaction"
            id="last_interaction"
            onSortChange={handleSortChange}
            activeSortKey={sortConfig.key}
          />
        </Table.Head>
        <Table.Body>
          {sortedAndFilteredUsers?.map((user) => (
            <Table.Row key={user.email}>
              <span>{user.email}</span>
              <span>
                <select
                  className="select select-bordered select-sm w-full max-w-xs"
                  value={user.role}
                  onChange={(e) => {
                    const role = e.target.value;
                    changeUserRole.mutate({ email: user.email, role });
                  }}
                >
                  {roleOptions?.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </span>
              <span>{user.subscription.kind}</span>
              <span>{user.daily_ai_request_count}</span>
              <span>{user.last_interaction}</span>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default UserList;
