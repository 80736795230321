import React from "react";
import { Preferences, useApi } from "@api/api";
import CounterComponent from "@components/Profile/Preferences/CounterComponent";
import DietOptions from "@components/Profile/Preferences/DietOptions";
import AllergyOptions from "@components/Profile/Preferences/AllergyOptions";
import UnitSelector from "@components/Profile/Preferences/UnitSelector";
import ApplianceSelector from "@components/Profile/Preferences/ApplianceSelector";
import CuisinesSelector from "@components/Profile/Preferences/CuisinesSelector";
import SimpleTextInput from "@components/Profile/Preferences/DebouncedText";
import DefaultSpiceSelector from "@components/Profile/Preferences/DefaultSpiceSelector";
import CustomSwitch from "@components/Profile/Preferences/CustomSwitch";
import IngredientSelector from "@components/Form/IngredientSelector";

export const PreferencesEditor: React.FC<{ preferences: Preferences }> = ({
  preferences,
}) => {
  const { upsertPreferences } = useApi();
  const [customSpicesText, setCustomSpicesText] = React.useState<string>("");
  const [pantryIngredientsText, setPantryIngredientsText] =
    React.useState<string>("");

  if (!preferences) return null;

  const {
    number_of_adults,
    number_of_kids,
    preferred_diet,
    custom_diet,
    allergies,
    custom_allergies,
    measurement_units,
    appliances,
    cuisines,
    default_spices,
    use_custom_spices,
    custom_spices,
    pantry_ingredients,
    extra_notes,
  } = preferences || {};
  const changePreference = (fieldName: string, newValue: any) => {
    if (preferences[fieldName] !== newValue) {
      upsertPreferences.mutate({ ...preferences, [fieldName]: newValue });
    }
  };
  return (
    <div className="flex flex-col space-y-4 p-4 bg-neutral-content max-w-md text-black">
      <div className="bg-base-100 card">
        <div className="card-body">
          <div className="card-title">Number of people</div>
          <CounterComponent
            adultCount={number_of_adults}
            setAdultCount={(value) =>
              changePreference("number_of_adults", value)
            }
            kidCount={number_of_kids}
            setKidCount={(value) => changePreference("number_of_kids", value)}
          />
        </div>
      </div>

      <div className="bg-base-100 card">
        <div className="card-body">
          <div className="card-title">Preferred diet</div>
          <DietOptions
            selectedDiet={preferred_diet}
            setSelectedDiet={(x) => changePreference("preferred_diet", x)}
            size="small"
          />
          <label className="label block mt-4">
            <div className="font-light">Custom</div>
          </label>
          <SimpleTextInput
            initialValue={custom_diet}
            onSave={(x) => changePreference("custom_diet", x)}
            placeholder={"Halal, Smoothie, etc"}
          />
          <div className="text-xs font-light text-gray-600 text-center">
            This will override your preferred diet
          </div>
        </div>
      </div>

      <div className="bg-base-100 card">
        <div className="card-body">
          <div className="card-title">Allergies</div>
          <AllergyOptions
            selectedAllergies={allergies}
            setSelectedAllergies={(x) => changePreference("allergies", x)}
            size="small"
          />
          <label className="label block mt-4">
            <div className="font-light">Custom</div>
          </label>
          <SimpleTextInput
            initialValue={custom_diet}
            onSave={(x) => changePreference("custom_allergies", x)}
            placeholder={"I'm allergic to vegetables"}
          />
        </div>
      </div>

      <div className="bg-base-100 card">
        <div className="card-body">
          <div className="card-title">Default UOM</div>
          <UnitSelector
            selectedUnit={measurement_units}
            setSelectedUnit={(x) => changePreference("measurement_units", x)}
          />
        </div>
      </div>

      <div className="bg-base-100 card">
        <div className="card-body">
          <div className="card-title">Chef tools</div>
          <ApplianceSelector
            selectedAppliances={appliances}
            setSelectedAppliances={(x) => changePreference("appliances", x)}
          />
        </div>
      </div>

      <div className="bg-base-100 card">
        <div className="card-body">
          <div className="card-title">Cuisines preference</div>
          <CuisinesSelector
            selectedCuisines={cuisines}
            setSelectedCuisines={(x) => changePreference("cuisines", x)}
          />
        </div>
      </div>

      <div className="bg-base-100 card">
        <div className="card-body">
          <div className="card-title">Other</div>
          <label className="label block mt-4">
            <div className="flex items-center m-2 justify-between">
              Spices
              <CustomSwitch
                isCustomMode={use_custom_spices}
                toggleMode={() =>
                  changePreference("use_custom_spices", !use_custom_spices)
                }
              />
            </div>
          </label>
          {use_custom_spices ? (
            <IngredientSelector
              label=""
              placeholder="Parsley, Sage, Rosemary, Thyme"
              ingredientList={custom_spices}
              updateList={(x) => {
                changePreference("custom_spices", x);
                setCustomSpicesText("");
              }}
              ingredientText={customSpicesText}
              updateText={setCustomSpicesText}
            />
          ) : (
            <DefaultSpiceSelector
              selectedSpiceLevel={default_spices}
              setSelectedSpiceLevel={(x) =>
                changePreference("default_spices", x)
              }
              size="small"
            />
          )}
          <label className="label block mt-4">
            <div className="m-2">Always available ingredients</div>
          </label>
          <IngredientSelector
            label=""
            placeholder="Eggs, Cereal, Butter, Milk"
            ingredientList={pantry_ingredients}
            updateList={(x) => {
              changePreference("pantry_ingredients", x);
              setPantryIngredientsText("");
            }}
            ingredientText={pantryIngredientsText}
            updateText={setPantryIngredientsText}
          />
          <label className="label block mt-4">
            <div className="m-2">Extra notes</div>
          </label>
          <SimpleTextInput
            initialValue={extra_notes}
            onSave={(x) => changePreference("extra_notes", x)}
            placeholder={"Notes"}
          />
        </div>
      </div>
    </div>
  );
};
