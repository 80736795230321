import { HRecipe, useApi } from "@api/api";
import React, { useState } from "react";
import { ShareButton } from "@components/ShareButton";
import { Card, Modal } from "react-daisyui";
import IconCounter from "@components/IconCounter";
import { CgUser } from "react-icons/cg";
import RecipeView from "@components/RecipeView";
import EmptyPlate from "@assets/empty-plate.svg";
import { IoClose } from "react-icons/io5";
import { IoMdArrowRoundBack } from "react-icons/io";
import ShortLoading from "@components/ShortLoading";
import { RecipeComments } from "@components/RecipeComments";
import { StarRating, StarRatingRO } from "@components/StarRating";

export const RecipeHistoryView = () => {
  const { useProfileQuery } = useApi();
  const { data, isLoading, isError } = useProfileQuery();

  if (isLoading) return <ShortLoading />;
  if (isError) return <div>Error</div>;

  const history = data.recipe_history;

  return <RecipeGroupAndDetails recipes={history} title="Recipe History" />;
};
export const RecipeSavedView = () => {
  const { useProfileQuery } = useApi();
  const { data, isLoading, isError } = useProfileQuery();

  if (isLoading) return <ShortLoading />;
  if (isError) return <div>Error</div>;

  const history = data.saved_recipes;

  return <RecipeGroupAndDetails recipes={history} title="Saved Recipes" />;
};

interface RecipeGroupAndDetailsProps {
  recipes: HRecipe[];
  title?: string;
}

const RecipeGroupAndDetails: React.FC<RecipeGroupAndDetailsProps> = ({
  recipes,
  title,
}) => {
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  if (recipes.length === 0)
    return (
      <div className="flex justify-center items-center m-2">
        <div className="w-full mt-8">
          <p className="text-center font-bold text-2xl">No saved recipes!</p>
          <figure className="flex justify-center items-center m-4">
            <img src={EmptyPlate} alt="Empty Plate" className="w-3/4" />
          </figure>
          <p className="text-center text-xl m-8">
            Go make some recipes and click save to see things here.
          </p>
        </div>
      </div>
    );

  if (selectedRecipe)
    return (
      <div className="w-full">
        <div className="w-full p-2">
          <button className="btn" onClick={() => setSelectedRecipe(null)}>
            <IoMdArrowRoundBack />
          </button>
        </div>
        <RecipeDetails
          recipe={selectedRecipe}
          onClose={() => setSelectedRecipe(null)}
        />
      </div>
    );

  return (
    <div className="w-full">
      <div className="flex justify-center items-center m-2">
        <div className="w-full">
          <div className="m-6 text-center text-xl font-bold">
            You have {recipes.length} saved recipe(s)
          </div>
          <RecipeSelector
            recipes={recipes}
            onSelect={(recipe) => setSelectedRecipe(recipe)}
          />
        </div>
      </div>
    </div>
  );
};

interface RecipeSelectorProps {
  recipes: HRecipe[];
  onSelect: (recipe: HRecipe) => void;
}

const RecipeSelector: React.FC<RecipeSelectorProps> = ({
  recipes,
  onSelect,
}) => {
  return (
    <div className="grid grid-cols-1 space-y-4 m-3">
      {recipes.map((recipe) => (
        <RecipeCard key={recipe.id} recipe={recipe} onSelect={onSelect} />
      ))}
    </div>
  );
};

const RecipeCard: React.FC<{
  recipe: HRecipe;
  onSelect: (recipe: HRecipe) => void;
}> = ({ recipe, onSelect }) => {
  const { deleteRecipeMutation } = useApi();
  const [isOpen, setIsOpen] = useState(false);

  const handleDeleteRecipe = (event) => {
    event.stopPropagation();
    setIsOpen(true);
  };
  const handleConfirmDelete = () => {
    deleteRecipeMutation.mutate(recipe.id);
    setIsOpen(false);
  };
  console.log(recipe);

  return (
    <>
      <Card
        key={recipe.recipe.name}
        className="cursor-pointer relative shadow-2xl"
        onClick={() => onSelect(recipe)}
      >
        <div className="card-body">
          <h2 className="card-title">{recipe.recipe.name}</h2>
          <div className="flex card-subtitle">
            <IconCounter count={recipe.recipe.servings} Icon={CgUser} />
            <p className="ml-3">
              {recipe.recipe.macros.calories_per_serving} calories,{" "}
              {recipe.recipe.cooking_time}
            </p>
            <StarRatingRO initialValue={recipe.rating} />
          </div>
        </div>
        <IoClose
          className="text-error text-3xl absolute top-2 right-2"
          onClick={handleDeleteRecipe}
        />
      </Card>
      <Modal open={isOpen}>
        <h3 className="font-bold text-lg">Confirm Deletion</h3>
        <p className="py-4">Are you sure you want to delete this recipe?</p>
        <div className="modal-action">
          <button className="btn btn-danger" onClick={handleConfirmDelete}>
            Delete
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

interface RecipeDetailsProps {
  recipe: HRecipe;
  onClose: () => void;
}

const RecipeDetails: React.FC<RecipeDetailsProps> = ({ recipe }) => {
  return (
    <div className="card">
      <div className="card-body">
        <RecipeView recipe={recipe.recipe} />
        <RecipeComments id={recipe.id} initialValue={recipe.comments} />
        <StarRating id={recipe.id} initialValue={recipe.rating} />
      </div>
      <div className="card-actions justify-center m-4">
        <ShareButton id={recipe.id} />
      </div>
    </div>
  );
};
