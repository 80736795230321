import React from "react";

type CustomSwitchProps = {
  isCustomMode: boolean;
  toggleMode: () => void;
};

const CustomSwitch: React.FC<CustomSwitchProps> = ({
  isCustomMode,
  toggleMode,
}) => {
  return (
    <button className="btn btn-sm" onClick={toggleMode}>
      {isCustomMode ? "Custom" : "Basic"}
    </button>
  );
};

export default CustomSwitch;
