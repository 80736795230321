import { useState } from "react";
import UserList from "@components/Admin/UserList";
import FeedbackSection from "@components/Admin/Feedback";
import PromotionCodeSection from "@components/Admin/PromotionCodes";

const AdminView = () => {
  const [activeTab, setActiveTab] = useState("users");

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 overflow-auto p-4 text-l flex flex-col">
      <div className="flex justify-center space-x-4 mb-8 font-bold">
        <button
          className={`py-2 ${activeTab === "users" ? "text-blue-500" : ""}`}
          onClick={() => setActiveTab("users")}
        >
          Users
        </button>
        <button
          className={`py-2 ${activeTab === "feedback" ? "text-blue-500" : ""}`}
          onClick={() => setActiveTab("feedback")}
        >
          Feedback
        </button>
        <button
          className={`py-2 ${activeTab === "codes" ? "text-blue-500" : ""}`}
          onClick={() => setActiveTab("codes")}
        >
          Discount Codes
        </button>
      </div>

      {activeTab === "users" && (
        <div className="w-full flex flex-col">
          <UserList />
        </div>
      )}
      {activeTab === "feedback" && (
        <div>
          <FeedbackSection />
        </div>
      )}
      {activeTab === "codes" && (
        <div>
          <PromotionCodeSection />
        </div>
      )}
    </div>
  );
};

export default AdminView;
