import { useApi } from "@api/api";
import { useNavigate } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";
import ShortLoading from "@components/ShortLoading";

const ReleaseNotes = () => {
  const { useReleaseNotesQuery } = useApi();
  const navigate = useNavigate();
  const {
    data: releaseNotes,
    isLoading,
    isError,
    error,
  } = useReleaseNotesQuery();

  if (isLoading) return <ShortLoading />;
  if (isError) return <p>Error: {error.message}</p>;

  return (
    <div className="p-4">
      <button className="btn btn-block" onClick={() => navigate("/meal/new")}>
        <BiChevronLeft />
        Back
      </button>
      <pre>{releaseNotes}</pre>
    </div>
  );
};

export default ReleaseNotes;
