import Faq from "@components/Faq";

const faqs = [
  {
    question: "What is Mealbot?",
    answer:
      "Mealbot is an innovative app designed to help users discover new recipes, and figure out whats for dinner. It uses artificial intelligence to suggest recipes based on your preferences and available ingredients.",
  },
  {
    question: "How does Mealbot personalize recipe suggestions?",
    answer:
      "Mealbot personalizes recipes by learning your taste preferences, dietary needs, and cooking habits. The more you use Mealbot, the better it gets at suggesting recipes you'll love.",
  },
  {
    question: "Can Mealbot accommodate dietary restrictions?",
    answer:
      "Absolutely! Mealbot allows you to set dietary preferences such as vegetarian, vegan, gluten-free, and more to ensure the recipes suggested fit your dietary needs.",
  },
  {
    question: "Is there a subscription fee for Mealbot?",
    answer:
      "Mealbot offers both a free trial and then subscription. The free version includes all the features but is limited by time and how many meals you can generate, while the premium subscription offers enhanced personalization, almost limitless meal generation.",
  },
  {
    question: "Can I save my favorite recipes in Mealbot?",
    answer:
      "Yes, Mealbot allows you to save and organize your favorite recipes for easy access. You can create custom collections and revisit your favorites anytime.",
  },
  {
    question: "Is Mealbot available on both iOS and Android?",
    answer:
      "Yes, Mealbot is a web page and 'PWA' (Progressive Web App) that can be installed on both iOS and Android devices.",
  },
  {
    question:
      "How does Mealbot ensure the accuracy of nutritional information?",
    answer:
      "Mealbot is powered by chatGPT at its core, thus it does not validate any nutritional information. All nutritional information is provided by the recipe author and should be used as a guideline only.",
  },
  {
    question: "Can I share recipes from Mealbot with friends?",
    answer:
      "Yes, Mealbot offers a feature to easily share your favorite recipes with friends and family through social media or direct messaging.",
  },
  {
    question: "What makes Mealbot different from other recipe apps?",
    answer:
      "Mealbot stands out with its AI-driven personalization, comprehensive meal planning features, and an intuitive user interface designed to make cooking with what you have on hand a breeze.",
  },
];
const FaqView = () => {
  return (
    <div className="p-6 space-y-4 max-w-xl w-full text-xl font-bold">
      <Faq faqs={faqs} />
    </div>
  );
};

export default FaqView;
