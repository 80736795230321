import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import useAuth from "../hooks/useAuth";
import toast from "react-hot-toast";

export enum MealName {
  Breakfast = "Breakfast",
  Lunch = "Lunch",
  Dinner = "Dinner",
  Snack = "Snack",
}

export interface Preferences {
  appliances: string[];
  cuisines: string[];
  default_spices: "barebones" | "typical" | "exotic";
  use_custom_spices: boolean;
  custom_spices: string[];
  pantry_ingredients: string[];
  default_ingredients: string;
  extra_notes: string;
  number_of_adults: number;
  number_of_kids: number;
  measurement_units: "imperial" | "metric";
  allergies: string[];
  custom_allergies: string;
  preferred_diet: string;
  custom_diet: string;
  enable_admin_panel: boolean;
}

export interface Ingredient {
  name: string;
  description: string;
  quantity: string;
}

export interface Macros {
  calories_per_serving: number;
  carbs: string;
  fats: string;
  proteins: string;
}

export interface Recipe {
  name?: string;
  servings: number;
  cooking_time: string;
  shopping_list: string[];
  ingredients: Ingredient[];
  instructions: string;
  macros: Macros;
}

export interface HRecipe {
  id: string;
  title: string;
  recipe_text: string;
  recipe: Recipe;
  comments: string;
  rating: number;
}

type SubscriptionKind = "month_to_month" | "annual" | "trial";
export type MealCreationFeedbackRequest = {
  kind: string;
  aii_id: string;
  response: string;
};

interface Subscription {
  kind: SubscriptionKind;
  subscription_end: string;
  tokens: number;
  used_tokens: number;
  days_left: number;
}

export interface ProfileResult {
  role: string;
  email: string;
  preferences: Preferences;
  recipe_history: HRecipe[];
  saved_recipes: HRecipe[];
  is_onboarded: boolean;
  subscription: Subscription;
  daily_ai_request_count: number;
  daily_ai_request_limit: number;
  stripe_customer_id: string | null;
}

export interface UserResult {
  id: string;
  name: string;
  email: string;
  role: string;
  subscription: Subscription;
  daily_ai_request_count: number;
  daily_ai_request_limit: number;
  stripe_customer_id: string | null;
  token_use_history: object[];
  last_interaction: string;
}

export interface StripeSession {
  sessionId: string;
  sessionUrl: string;
}

export type MealRequest = {
  ingredients: string;
  ingredients_list: string[];
  must_use_ingredients: string;
  must_use_ingredients_list: string[];
  minutes_available: number;
  can_goto_store: boolean;
  meal_name: string;
  cuisines: string[];
  special_requests: string;
  number_of_adults: number;
  number_of_kids: number;
};
export type MealFormMetadataResponse = {
  default: MealRequest;
  cuisine_options: string[];
};
export const useApi = () => {
  const { authPost, isLoggedIn, logout } = useAuth();
  const queryClient = useQueryClient();

  const shareRecipeMutation = useMutation({
    mutationFn: async (id: string) => authPost("share_recipe", { id }),
  });
  const useGetSharedRecipeQuery = (id: string) => {
    return useQuery({
      queryKey: ["sharedRecipe", id],
      queryFn: async () => authPost("get_shared_recipe", { id }),
      enabled: !!id,
    });
  };

  const useMealOptionsQuery = (
    data: any,
    enabled: boolean = true,
  ): UseQueryResult<string[], unknown> => {
    return useQuery({
      queryKey: ["mealOptions", data],
      queryFn: async () => authPost("list_meal_options", data),
      enabled: isLoggedIn && enabled,
    });
  };
  const useHowToMakeQuery = (data: any): UseQueryResult<string[], unknown> =>
    useQuery({
      queryKey: ["howToMake", data],
      queryFn: async () => authPost("tell_me_how_to_make", data),
      enabled: isLoggedIn,
    });

  const useReshowRecipeQuery = (
    aii_id: string,
  ): UseQueryResult<string[], unknown> =>
    useQuery({
      queryKey: ["reshowRecipe", aii_id],
      queryFn: async () => authPost("reshow_recipe", { aii_id }),
      enabled: isLoggedIn,
    });

  const useMealFormMetadataQuery = () =>
    useQuery<MealFormMetadataResponse>({
      queryKey: ["mealFormMetadata"],
      queryFn: async () => {
        return authPost("get_meal_form_metadata");
      },
      enabled: isLoggedIn,
    });

  const useProfileQuery = (): UseQueryResult<ProfileResult, unknown> =>
    useQuery({
      queryKey: ["profile"],
      queryFn: async () => authPost("get_profile"),
      enabled: isLoggedIn,
    });

  const upsertPreferences = useMutation({
    mutationFn: async (preferences: Preferences) =>
      authPost("update_preferences", preferences),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["profile"] }),
  });

  const redeemCode = useMutation({
    mutationFn: async (code: string) =>
      authPost("subscription/redeem_code", { code }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
      toast.success("Code redeemed", { id: "redeemCode" });
    },
    onError: (e) => {
      toast.error(`Error redeeming code`, { id: "redeemCode" });
    },
  });

  type OnBoardRequest = {
    number_of_adults: number;
    number_of_kids: number;
    preferred_diet: string;
    allergies: string[];
  };

  const onboardingMutation = useMutation<void, Error, OnBoardRequest>({
    mutationFn: (data: OnBoardRequest) => authPost("onboard", data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["profile"] }),
  });

  const giveFeedback = useMutation({
    mutationFn: async (data: MealCreationFeedbackRequest) =>
      authPost("give_feedback", data),
    onSuccess: () => {
      toast.success("Feedback submitted", { id: "giveFeedback" });
    },
    onError: (e) => {
      toast.error(`Error submitting feedback`, { id: "giveFeedback" });
    },
  });

  const useReleaseNotesQuery = (): UseQueryResult<string, unknown> =>
    useQuery({
      queryKey: ["releaseNotes"],
      queryFn: async () => authPost("release_notes"),
      enabled: isLoggedIn,
    });

  const saveRecipeMutation = useMutation({
    mutationFn: async (id: string) => authPost("save_recipe", { id }),
    onSuccess: () => {
      toast.success("Recipe saved", { id: "saveRecipe" });
      queryClient.invalidateQueries({ queryKey: ["profile"] });
    },

    onError: () => {
      toast.error("Error saving recipe", { id: "saveRecipe" });
    },
  });

  const commentOnRecipeMutation = useMutation({
    mutationFn: async ({ id, comments }: { id: string; comments: string }) =>
      authPost("comment_recipe", { id, comments }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["profile"] }),
    onError: () => {
      toast.error("Error commenting recipe", { id: "commentRecipe" });
    },
  });
  const rateRecipeMutation = useMutation({
    mutationFn: async ({ id, rating }: { id: string; rating: number }) =>
      authPost("rate_recipe", { id, rating }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["profile"] }),
    onError: () => {
      toast.error("Error rating recipe", { id: "commentRecipe" });
    },
  });

  const deleteAccount = useMutation({
    mutationFn: async () => authPost("delete_account"),
    onSuccess: () => {
      toast.success("Account deleted", { id: "deleteAccount" });
      logout();
    },
    onError: () => {
      toast.error("Error deleting account", { id: "deleteAccount" });
    },
  });

  const deleteRecipeMutation = useMutation({
    mutationFn: async (id: string) => authPost("delete_recipe", { id }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["profile"] }),
  });

  const convertIngredients = async (
    ingredients: string,
    ingredient_list: string[],
  ) => authPost("convert_to_ingredient_list", { ingredients, ingredient_list });
  const createCheckoutSession = async (kind: string): Promise<StripeSession> =>
    authPost("subscription/create-checkout-session", {
      kind,
      base_url: window.location.origin,
    });

  const createBillingPortalSession = async (): Promise<StripeSession> =>
    authPost("subscription/create-billing-portal-session", {
      base_url: window.location.origin,
    });

  return {
    createCheckoutSession,
    createBillingPortalSession,
    convertIngredients,
    useGetSharedRecipeQuery,
    shareRecipeMutation,
    useMealOptionsQuery,
    useHowToMakeQuery,
    useReshowRecipeQuery,
    useMealFormMetadataQuery,
    useProfileQuery,
    useReleaseNotesQuery,
    saveRecipeMutation,
    deleteRecipeMutation,
    onboardingMutation,
    queryClient,
    upsertPreferences,
    redeemCode,
    giveFeedback,
    deleteAccount,
    commentOnRecipeMutation,
    rateRecipeMutation,
  };
};
