import React, { useEffect } from 'react';
import { useAdminApi } from "@api/adminApi";
import ShortLoading from "./ShortLoading";
import PropTypes from 'prop-types';
import TimestampFormatter from "./TimeStampFormatter";

const AIInteractionModal = ({ currentAIIID, onClose, isOpen }) => {
  const { useAIInteractionQuery } = useAdminApi();

  const aiInteractionQuery = useAIInteractionQuery(currentAIIID);
  const { data: aiInteraction, isLoading } = aiInteractionQuery;

  const copyToClipboard = (text: string) => {
    if(navigator.clipboard) {
      navigator.clipboard.writeText(text);
    }else{
      alert("Clipboard not supported");
    }
  };

  if (!isOpen) return null;

  if (isLoading) return <ShortLoading />;

  return (
    <div className="modal modal-open">
      <div className="modal-box">
        <h3 className="font-bold text-lg">AI Interaction (ID: {currentAIIID})</h3>
        <div className="flex items-start mb-3 w-2/3">
          <details className="details flex-1 w-2/3">
            <summary className="summary">Request</summary>
            <pre className="pre pre text-xs mt-3 pl-5">{aiInteraction.prompt}</pre>
          </details>
          <button className="btn btn-secondary btn-xs ml-2 w-1/3" onClick={() => copyToClipboard(aiInteraction.prompt)}>
            Copy Request
          </button>
        </div>
        <div className="flex items-start mb-3 w-2/3">
          <details className="details flex-1 w-3/4">
            <summary className="summary">Response</summary>
            <pre className="pre pre text-xs mt-3 pl-5">{aiInteraction.response}</pre>
          </details>
          <button className="btn btn-secondary btn-xs ml-2 w-1/3" onClick={() => copyToClipboard(aiInteraction.response)}>
            Copy Response
          </button>
        </div>
        <div className="text-sm">Timestamp: <TimestampFormatter timestamp={aiInteraction.timestamp} /></div>
        <div className="text-sm">Prompt Tokens: {aiInteraction.prompt_tokens}</div>
        <div className="text-sm">Response Tokens: {aiInteraction.response_tokens}</div>
        <div className="modal-action">
          <button className="btn btn-error btn-outline" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

AIInteractionModal.propTypes = {
    currentAIIID: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default AIInteractionModal;
