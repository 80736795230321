import { useApi } from "@api/api";
import toast from "react-hot-toast";
import { shareOnMobile } from "react-mobile-share";
import React from "react";
import { CgShare } from "react-icons/cg";
import { tagEvent } from "@components/util";

export const ShareButton: React.FC<{ id: string }> = ({ id }) => {
  const { shareRecipeMutation } = useApi();
  return (
    <button
      className="btn btn-secondary"
      onClick={() =>
        shareRecipeMutation.mutate(id, {
          onSuccess: (data) => {
            tagEvent("recipe_view_share", { button_name: "Share" });
            shareOnMobile(
              {
                text: "Here's a recipe I made with MealBot!",
                url: `${window.location.origin}/shared-recipe?recipe_id=${id}`,
                title: "MealBot Recipe",
              },
              async (_) => {
                try {
                  await navigator.clipboard.writeText(
                    `${window.location.origin}/shared-recipe?recipe_id=${id}`,
                  );
                  toast.success("Link copied to clipboard!");
                } catch (error) {
                  if (
                    error instanceof DOMException &&
                    error.name === "NotAllowedError"
                  ) {
                    toast.error(
                      "Copying to clipboard failed. This action is likely blocked by your browser",
                    );
                  } else {
                    toast.error(
                      "An unknown error occurred while copying the link.",
                    );
                  }
                }
              },
            );
          },
        })
      }
    >
      <CgShare />
      Share
    </button>
  );
};
