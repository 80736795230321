import React, { useState } from "react";
import { useApi } from "@api/api";

interface StarRatingProps {
  id: string;
  initialValue: number;
}

export const StarRating: React.FC<StarRatingProps> = ({ id, initialValue }) => {
  const [rating, setRating] = useState<number>(initialValue);
  const { rateRecipeMutation } = useApi();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRating(Number(event.target.value));
    rateRecipeMutation.mutate({ id, rating: Number(event.target.value) });
  };

  return (
    <div className="flex flex-col items-center m-4">
      <div className="font-bold mb-2">Rate this recipe:</div>
      <div className="rating gap-1 rating-lg">
        {[...Array(5)].map((_, index) => {
          index += 1;
          return (
            <input
              key={index}
              type="radio"
              name="rating-1"
              value={index}
              className={`mask mask-star-2 bg-orange-300`}
              checked={index === rating}
              onChange={handleChange}
            />
          );
        })}
      </div>
    </div>
  );
};
interface StarRatingROProps {
  initialValue: number;
}
export const StarRatingRO: React.FC<StarRatingROProps> = ({ initialValue }) => {
  return (
    <div className="rating gap-1 rating-lg">
      {[...Array(initialValue)].map((_, index) => (
        <div key={index} className="text-xl text-orange-300">
          ★
        </div>
      ))}
    </div>
  );
};
