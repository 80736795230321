import React from "react";

type MealSelectorProps = {
  selectedMeal: string;
  setSelectedMeal: React.Dispatch<React.SetStateAction<string>>;
};

const MealSelector: React.FC<MealSelectorProps> = ({
  selectedMeal,
  setSelectedMeal,
}) => {
  const meals: string[] = ["Breakfast", "Lunch", "Dinner", "Snack"];

  const sizeClasses = "font-light";

  return (
    <div className="flex flex-wrap items-center justify-center join">
      {meals.map((meal) => (
        <button
          key={meal}
          className={`btn join-item ${sizeClasses} ${
            selectedMeal === meal ? "btn-primary" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            setSelectedMeal(meal);
          }}
        >
          {meal.charAt(0).toUpperCase() + meal.slice(1)}
        </button>
      ))}
    </div>
  );
};

export default MealSelector;
