import React from "react";
import { capitalizeFirstLetter } from "@components/util";

type UnitSelectorProps = {
  selectedUnit: "imperial" | "metric";
  setSelectedUnit: React.Dispatch<React.SetStateAction<"imperial" | "metric">>;
  size?: "small" | "large";
};

const UnitSelector: React.FC<UnitSelectorProps> = ({
  selectedUnit,
  setSelectedUnit,
  size = "small",
}) => {
  const units: ("imperial" | "metric")[] = ["imperial", "metric"];

  const unitSymbols: { [key in "imperial" | "metric"]: string } = {
    imperial: "🇺🇸",
    metric: "🌍",
  };

  const handleSelect = (unit: "imperial" | "metric") => {
    setSelectedUnit(unit);
  };

  const sizeClasses =
    size === "small" ? "btn-sm text-lg font-light" : "btn-lg text-3xl";

  return (
    <div className="flex flex-wrap gap-2 items-center justify-center">
      {units.map((unit) => (
        <button
          key={unit}
          className={`btn ${sizeClasses} ${
            selectedUnit === unit ? "btn-primary" : ""
          }`}
          onClick={() => handleSelect(unit)}
        >
          {unitSymbols[unit]} {capitalizeFirstLetter(unit)}
        </button>
      ))}
    </div>
  );
};

export default UnitSelector;
