import React, { createContext, ReactNode, useState } from "react";
import { MealName } from "@api/api";
export interface NewMealState {
  ingredients: string;
  ingredients_list: string[];
  must_use_ingredients: string;
  must_use_ingredients_list: string[];
  minutes_available: number;
  can_goto_store: boolean;
  meal_name: MealName;
  cuisines: string[];
  selected_meal: string;
  skipped_dishes?: string[];
  special_requests?: string;
}

interface Option {
  recipe_name:string;
  calories: string;
  difficulty:string;
}

export interface MealOptions {
  options: Option[];
  aii_id?: string;
}

interface PageText {
  title: string;
  subTitle: string;
  questions: string[];
}

interface TitleObject extends Record<number, PageText> {}

const NewMealContext = createContext<{
  mealState: NewMealState;
  setMealState: React.Dispatch<React.SetStateAction<NewMealState>>;
  skipDishes: (dishes: string[]) => void;
  mealOptions: MealOptions;
  setMealOptions: React.Dispatch<React.SetStateAction<MealOptions>>;
  clearMealOptions: () => void;
  handleTimeSelectionChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handleMealNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCanGotoStoreChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
} | null>(null);

export const NewMealContextProvider: React.FC = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [mealState, setMealState] = useState<NewMealState>({
    can_goto_store: false,
    cuisines: [],
    minutes_available: 0,
    selected_meal: "",
    skipped_dishes: [],
    ingredients: "",
    ingredients_list: [],
    must_use_ingredients: "",
    must_use_ingredients_list: [],
    meal_name: MealName.Breakfast,
    special_requests: "",
  });
  const [mealOptions, setMealOptions] = useState({ options: [] });

  const selectMeal = (newMealName: string) => {
    setMealState((prevState) => ({ ...prevState, selected_meal: newMealName }));
  };

  const skipDishes = (dishes: string[]) => {
    setMealState((prevState) => ({
      ...prevState,
      skipped_dishes: (prevState.skipped_dishes || []).concat(dishes),
    }));
  };

  const handleTimeSelectionChange = (event) => {
    setMealState({
      ...mealState,
      minutes_available: Number(event.target.value),
    });
  };

  const handleMealNameChange = (event) => {
    setMealState({ ...mealState, meal_name: event.target.value });
  };
  const handleCanGotoStoreChange = (event) => {
    const nextValue = event.target.value === "true";
    setMealState({ ...mealState, can_goto_store: nextValue });
  };

  const clearMealOptions = () => {
    setMealOptions({ options: [] });
  };

  function setIngredientList(newList, clearText) {
    setMealState((prevState) => ({
      ...prevState,
      ingredients_list: newList,
      ingredients: clearText ? "" : prevState.ingredients,
    }));
  }

  function setMustUseIngredientList(newList, clearText) {
    setMealState((prevState) => ({
      ...prevState,
      must_use_ingredients_list: newList,
      must_use_ingredients: clearText ? "" : prevState.must_use_ingredients,
    }));
  }

  function setIngredientText(newText) {
    setMealState({
      ...mealState,
      ingredients: newText,
    });
  }

  function setMustUseIngredientText(newText) {
    setMealState({
      ...mealState,
      must_use_ingredients: newText,
    });
  }

  function setSpecialRequestsText(newText) {
    setMealState({
      ...mealState,
      special_requests: newText,
    });
  }

  const value = React.useMemo(
    () => ({
      mealState,
      setMealState,
      selectMeal,
      skipDishes,
      mealOptions,
      setMealOptions,
      clearMealOptions,
      handleMealNameChange,
      handleCanGotoStoreChange,
      handleTimeSelectionChange,
    }),
    [
      mealState,
      setMealState,
      mealOptions,
      setMealOptions,
      clearMealOptions,
      handleMealNameChange,
      handleCanGotoStoreChange,
      handleTimeSelectionChange,
    ],
  );

  return (
    <NewMealContext.Provider value={value}>{children}</NewMealContext.Provider>
  );
};

export default NewMealContext;
