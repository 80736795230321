import { useNavigate } from "react-router-dom";
import { useApi } from "@api/api";
import { tagEvent } from "@components/util";

const ManageSubscriptionButton = () => {
  const { createBillingPortalSession } = useApi();
  const { useProfileQuery } = useApi();
  const { data: profile } = useProfileQuery();

  if (!profile) return null;
  if (!profile.stripe_customer_id || profile.subscription.kind === "trial")
    return <SubButton />;

  const handleManageSubscription = async () => {
    tagEvent("manage_subscription_button_click", {});
    try {
      const { sessionUrl } = await createBillingPortalSession();
      window.location.assign(sessionUrl);
    } catch (error) {
      console.error("Error creating checkout session: ", error);
    }
  };

  return (
    <button
      className="btn flex items-center"
      onClick={handleManageSubscription}
    >
      Change
    </button>
  );
};

const SubButton = () => {
  const navigate = useNavigate();
  return (
    <button
      className="btn btn-primary"
      onClick={() => {
        tagEvent("subscribe_button_click", {});
        navigate("/subscription/new");
      }}
    >
      <p className="">Purchase</p>
    </button>
  );
};

export default ManageSubscriptionButton;
