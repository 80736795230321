import React, { useState } from "react";
import useAuth from "@hooks/useAuth";
import { useApi } from "@api/api";
import { tagEvent } from "@components/util";

export const DangerZone: React.FC = () => {
  const { deleteAccount } = useApi();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { logout } = useAuth();
  return (
    <div className="flex justify-between">
      <button
        className="btn btn-error btn-outline"
        onClick={() => setIsModalOpen(true)}
      >
        Delete Account
      </button>
      <button id='logout-button' className="btn btn-error" onClick={logout}>
        Logout
      </button>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => {
          tagEvent("profile_btn_delete", { button_name: "Delete Account" });
          deleteAccount.mutate();
        }}
      />
    </div>
  );
};
type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal modal-open">
      <div className="modal-box bg-base-content">
        <h3 className="font-bold text-lg">Are You Sure?</h3>
        <p className="py-4">
          Do you really want to delete your account? This deletes all the
          information about you on this app including saved recipes, and your
          profile. This action cannot be undone.
        </p>
        <div className="modal-action">
          <button className="btn btn-error" onClick={onConfirm}>
            Delete
          </button>
          <button className="btn btn-ghost" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
