import LoggedInLayout from "@components/LoggedInLayout";
import LandingPage from "@views/landing.view";
import LoginView from "@views/login.view";
import HomeView from "@views/home.view.jsx";
import ProfileView from "@views/profile.view";
import SelectMealView from "@views/selectMeal.view";
import FinalRecipeView from "@views/finalRecipeView";
import ReleaseNotes from "@views/release_notes.view";
import NewMealForm from "@components/NewMealForm";
import { RecipeHistoryView, RecipeSavedView } from "@views/recipeHistory.view";
import { createBrowserRouter, Outlet } from "react-router-dom";
import { NewMealContextProvider } from "@contexts/NewMealContext";
import SharedRecipeView from "@views/sharedRecipe.view";
import SubscribeView from "@views/subscribe.view";
import ThankYouView from "@views/thankYou.view";
import ManualLoginView from "@views/manualLogin.view";
import ProtectedRoute from "@components/Routing/ProtectedRoute";
import ErrorPage from "@components/Routing/ErrorPage";
import FaqView from "@views/faq.view";
import OnBoardingView from "@views/onboarding.view";
import AdminRoute from "@components/Routing/AdminRoute";
import TrialExpiredView from "@views/trialExpired.view";
import RateLimitView from "@views/rateLimit.view";

let makeRouter = createBrowserRouter;
export const paths = {
  "/home": { title: "Home" },
  "/profile": { title: "Profile" },
};

const router = makeRouter([
  {
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/login",
        element: <LoginView path="/meal/new" />,
      },
      {
        path: "/local-login",
        element: <ManualLoginView path="/meal/new" />,
      },
      {
        path: "/shared-recipe",
        element: <SharedRecipeView />,
      },
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <LoggedInLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: "trialEnded",
            element: <TrialExpiredView />,
          },
          {
            path: "slowDown",
            element: <RateLimitView />,
          },
          {
            path: "home",
            element: <HomeView />,
          },
          {
            path: "on-boarding",
            element: <OnBoardingView />,
          },
          {
            path: "faq",
            element: <FaqView />,
          },
          {
            path: "subscription",
            children: [
              {
                path: "new",
                element: <SubscribeView />,
              },
              {
                path: "onComplete",
                element: <ThankYouView />,
              },
            ],
          },
          {
            path: "more",
            element: <ProfileView />,
          },
          {
            path: "recipe-history",
            element: <RecipeHistoryView />,
          },
          {
            path: "recipe-saved",
            element: <RecipeSavedView />,
          },
          {
            path: "release-notes",
            element: <ReleaseNotes />,
          },
          {
            path: "meal",
            element: (
              <NewMealContextProvider>
                <Outlet />
              </NewMealContextProvider>
            ),
            children: [
              {
                path: "new",
                element: <NewMealForm />,
              },
              {
                path: "select",
                element: <SelectMealView />,
              },
              {
                path: "recipe",
                element: <FinalRecipeView />,
              },
            ],
          },
          {
            path: "admin",
            element: <AdminRoute />,
          },
        ],
      },
    ],
  },
]);

export default router;
