import React from "react";
import { CgMathMinus, CgMathPlus } from "react-icons/cg";

type CounterComponentProps = {
  adultCount: number;
  setAdultCount: React.Dispatch<React.SetStateAction<number>>;
  kidCount: number;
  setKidCount: React.Dispatch<React.SetStateAction<number>>;
};

const CounterComponent: React.FC<CounterComponentProps> = ({
  adultCount,
  setAdultCount,
  kidCount,
  setKidCount,
}) => {
  const addAdult = (e) => {
    e.preventDefault();
    setAdultCount(adultCount <= 9 ? adultCount + 1 : adultCount);
  };

  const removeAdult = (e) => {
    e.preventDefault();
    setAdultCount(adultCount >= 1 ? adultCount - 1 : adultCount);
  };

  const addKid = (e) => {
    e.preventDefault();
    setKidCount(kidCount <= 9 ? kidCount + 1 : kidCount);
  };

  const removeKid = (e) => {
    e.preventDefault();
    setKidCount(kidCount >= 1 ? kidCount - 1 : kidCount);
  };

  return (
    <div className="flex flex-col space-y-2">
      <Counter
        title="Adults"
        increment={addAdult}
        decrement={removeAdult}
        count={adultCount}
        iconSize={14}
        max={10}
      />
      <Counter
        title="Kids"
        increment={addKid}
        decrement={removeKid}
        count={kidCount}
        iconSize={14}
        max={10}
      />
    </div>
  );
};

type CounterProps = {
  title: string;
  increment: (e) => void;
  decrement: (e) => void;
  iconSize: number;
  count: number;
  max: number;
  min?: number;
};

export const Counter: React.FC<CounterProps> = ({
  title,
  increment,
  decrement,
  iconSize,
  count,
  max,
  min = 0,
}) => {
  return (
    <div className="flex items-center">
      <p className="mr-16">{title}</p>
      <div className="ml-auto flex items-center justify-center space-x-4">
        <button
          onClick={decrement}
          className="btn btn-square btn-sm"
          disabled={count === min}
        >
          <CgMathMinus size={iconSize} />
        </button>
        <div className="font-light">{count}</div>
        <button
          onClick={increment}
          className="btn btn-square btn-sm"
          disabled={count === max}
        >
          <CgMathPlus size={iconSize} />
        </button>
      </div>
    </div>
  );
};

export default CounterComponent;
