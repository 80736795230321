import React, { useState } from "react";
import SearchableSelect from "@components/SearchableSelect";

type DiscountCodeModalProps = {
  onSubmit: () => void;
  onCancel: () => void;
  userArray: any[];
  isLoading: boolean;
};

const DiscountCodeModal: React.FC<DiscountCodeModalProps> = ({
  onSubmit,
  onCancel,
  userArray,
  isLoading,
}) => {
  const [response, setResponse] = useState("");
  const [uniqueCodeID, setUniqueCodeID] = useState(false);
  const [checkingCodeID, setCheckingCodeID] = useState(false);

  const submitDiscountCode = (kind: string) => {
    onSubmit();
  };

  console.log(userArray)

  return (
    <div className="modal modal-open">
      <div className="modal-box w-1/2 h-1/2 flex flex-col overflow-y-scroll">
        <div className="flex-grow" style={{zIndex: 0}}>
          <h3 className="font-bold text-lg mb-3">Creating Discount Code</h3>
          <label className="label">Assigned User</label>
          <SearchableSelect 
            data={userArray}
            isLoading={isLoading}
            fieldName="email"
            maxResults={10}
            otherOption="Not Assigned"
          />
          <label className="label mt-3">Code ID</label>
          <div className="relative">
            <input
              className="input input-bordered w-full"
              placeholder="Enter code ID..."
              value={response}
              onChange={(e) => setResponse(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
              {checkingCodeID && (
                <div className="loading loading-spinner loading-sm"></div>
              )}
              {!checkingCodeID && (
                <div className="text-xs text-green-500">
                  {uniqueCodeID && (
                    <>Code ID is unique</>
                  )}
                </div>
              )}
            </div>
          </div>
          <span className="text-xs text-red-500 ml-4 mr-4">
            {!uniqueCodeID && (
              <>*Code ID must be unique</>
            )}
          </span>
        </div>
        <div className="modal-action mt-3 flex justify-end">
          <button className="btn btn-error btn-outline" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
  
};

export default DiscountCodeModal;
