import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { useApi, UserResult } from "@api/api";
import useAuth from "@hooks/useAuth";

type AdminMetadataResult = {
  roles: string[];
};

export const useAdminApi = () => {
  const { authPost, isLoggedIn } = useAuth();
  const { useProfileQuery } = useApi();
  const { data: profile } = useProfileQuery();
  const queryClient = useQueryClient();

  const isAdmin = profile?.role === "admin";

  type UserSearchRequest = {
    max_users?: number;
  }

  const useUserListQuery = (max_users: number) => {
    return useQuery({
      queryKey: ['adminUserList', max_users],
      queryFn: async () => {
        return authPost("admin/get_users", {max_users})
      },
      enabled: isLoggedIn && isAdmin,
    });
  };
  

  const useAdminMetadataQuery = (): UseQueryResult<
    AdminMetadataResult,
    unknown
  > =>
    useQuery({
      queryKey: ["adminMetadata"],
      queryFn: async () => authPost("admin/metadata"),
      enabled: isLoggedIn && isAdmin,
    });

  type ChangeUserRoleRequest = {
    email: string;
    role: string;
  };

  const changeUserRole = useMutation({
    mutationFn: async (req: ChangeUserRoleRequest) =>
      authPost("admin/change_user_role"),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["adminUserList"] }),
  });

  const useFeedbackQuery = (
    max_feedback: number, 
    include_archived: boolean,
    sentiment: string,
    search: string,
  ) => {
    return useQuery({
      queryKey: ["adminFeedback", max_feedback, include_archived, sentiment, search],
      queryFn: async () => authPost("admin/get_feedback", { max_feedback, include_archived, sentiment, search }),
      enabled: isLoggedIn && isAdmin,
    });
  }

  const archiveFeedback = useMutation({
    mutationFn: async (id: string) => authPost("admin/archive_feedback", { id }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["adminFeedback"] }),
  });

  const useAIInteractionQuery = (id: string) => {
    return useQuery({
      queryKey: ["adminAIInteraction", id],
      queryFn: async () => authPost("admin/get_ai_interaction", {id }),
      enabled: isLoggedIn && isAdmin,
    });
  }

  const createPromotionCode = useMutation({
    mutationFn: async (req: CreatePromotionCodeRequest) =>
      authPost("admin/create_promotion_code", req),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["adminPromotionCodes"] }),
  });

  const useGetPromoCodeInformation = () => {
    return useQuery({
      queryKey: ["adminPromotionCodes"],
      queryFn: async () => authPost("admin/init_promotion_code_creation"),
      enabled: isLoggedIn && isAdmin
    });
  }

  return {
    useUserListQuery,
    useAdminMetadataQuery,
    changeUserRole,
    useFeedbackQuery,
    archiveFeedback,
    useAIInteractionQuery,
    createPromotionCode,
    useGetPromoCodeInformation,
  };
};

export type AIInteractionResponse = {
  kind: string;
  prompt: string;
  prompt_tokens: number;
  response: string;
  response_tokens: number;
  timestamp: string;
  title: string;
  user: string;
};

export type CreatePromotionCodeRequest = {
  promotion_code: string;
  max_uses: number;
  active: boolean;
  assigned_to: string;
};
