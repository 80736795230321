import { useNavigation } from "@hooks/useNavigation";
import Avatar from "@components/Profile/Avatar";

const TopCard = () => {
  const { currentPathName, profileComponents } = useNavigation();

  return (
    <div className="flex flex-col space-y-4 w-full">
      <div className="bg-base-100 rounded-b-lg shadow min-h-[8rem] -mt-2 py-8 px-4">
        <div className="flex flex-col space-y-4 items-center text-black">
          <Avatar icon={profileComponents[currentPathName].icon}></Avatar>
          <p className="italic text-black text-center text-sm">
            {profileComponents[currentPathName].description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopCard;
