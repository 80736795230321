import React from "react";

type AllergyOptionsProps = {
  selectedAllergies: string[];
  setSelectedAllergies: React.Dispatch<React.SetStateAction<string[]>>;
  size?: "small" | "large";
};

const AllergyOptions: React.FC<AllergyOptionsProps> = ({
  selectedAllergies,
  setSelectedAllergies,
  size = "large",
}) => {
  const allergies: string[] = [
    "Peanuts",
    "All Nuts",
    "Gluten",
    "Dairy",
    "Shellfish",
    "Eggs",
    "Soy",
  ];
  const allergyEmojis: { [key: string]: string } = {
    Peanuts: "🥜",
    "All Nuts": "🐿️",
    Gluten: "🍞",
    Dairy: "🐄",
    Shellfish: "🦐",
    Eggs: "🥚",
    Soy: "🌱",
  };

  const handleSelect = (allergy: string) => {
    if (selectedAllergies.includes(allergy)) {
      setSelectedAllergies(
        selectedAllergies.filter((item) => item !== allergy),
      );
    } else {
      setSelectedAllergies([...selectedAllergies, allergy]);
    }
  };

  const sizeClasses =
    size === "small" ? "btn-sm text-lg font-light" : "btn-lg text-medium";

  return (
    <div>
      <div className="flex flex-wrap gap-2 items-center justify-center">
        {allergies.map((allergy) => (
          <button
            key={allergy}
            className={`btn ${sizeClasses} ${
              selectedAllergies.includes(allergy) ? "btn-primary" : ""
            }`}
            onClick={() => handleSelect(allergy)}
          >
            {allergyEmojis[allergy]} {allergy}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AllergyOptions;
