import PropTypes from 'prop-types';
import { FaSearch } from "react-icons/fa";

const SearchBar = ({ onSearch }) => {
  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      onSearch(event.target.value);
    }
  };

  return (
    <div className="relative">
      <input
        className="input input-bordered w-full max-w-xs input-sm"
        type="text"
        placeholder="Search..."
        onKeyDown={handleSearch}
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <FaSearch />
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchBar;