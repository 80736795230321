import React from "react";
import { Accordion } from "react-daisyui";

type FaqItem = {
  question: string;
  answer: string;
};

type FaqProps = {
  faqs: FaqItem[];
};


const Faq: React.FC<FaqProps> = ({ faqs }) => {
  return (
    <div className="flex flex-wrap gap-2">
      {faqs.map((faq, index) => (
        <Accordion key={index} className="border bg-base-100 text-black">
          <Accordion.Title className="text-lg font-medium">
            {faq.question}
          </Accordion.Title>
          <Accordion.Content className="text-sm font-light">
            <p>{faq.answer}</p>
          </Accordion.Content>
        </Accordion>
      ))}
    </div>
  );
};

export default Faq;
