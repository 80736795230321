import { useApi } from "@api/api";
import { Divider } from "react-daisyui";
import EmailAndRole from "@components/Profile/EmailAndRole";
import React from "react";
import ShortLoading from "@components/ShortLoading";
import PreferenceButton from "@components/Profile/PreferenceButton";
import ToggleComponent from "@components/Profile/Preferences/ToggleSelector";
const ProfileView: React.FC = () => {
  const { useProfileQuery, upsertPreferences } = useApi();

  const { data, isLoading, isError, error } = useProfileQuery();
  const { preferences, email, role } = data || {};
  const { enable_admin_panel } = preferences || {};
  const changePreference = (fieldName: string, newValue: any) => {
    if (preferences[fieldName] !== newValue) {
      upsertPreferences.mutate({ ...preferences, [fieldName]: newValue });
    }
  };
  const isAdmin = role === "admin";

  if (isLoading) return <ShortLoading />;
  if (isError) return <div>Error {error.toString()}</div>;

  return (
    <div className="p-4">
      <div className="flex flex-col h-full space-y-8 w-full">
        <div className="flex-grow">
          <EmailAndRole email={email} role={role} type="button" />
          <Divider className="divider divider-neutral-content"></Divider>
          <PreferenceButton label="Manage Preferences" />
          <Divider className="divider divider-neutral-content"></Divider>
          <PreferenceButton label="FAQs" />
          <Divider className="divider divider-neutral-content"></Divider>
          <PreferenceButton
            label="Feedback"
            type="link"
            href="https://feedback.mealbot.app/"
          />
          <Divider className="divider divider-neutral-content"></Divider>
          <PreferenceButton
            label="Focus group form"
            type="link"
            href="https://docs.google.com/forms/d/1FB2wTB6kp0YAfHAjLYPmM53nMusEgJQvkBsHRehx6xY/prefill"
          />
          {isAdmin && (
            <div>
              <Divider className="divider divider-neutral-content" />
              <PreferenceButton label="Admin Panel" type="link" href="/admin" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileView;
