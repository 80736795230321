import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "@hooks/useAuth";
import loginImage from "@assets/landin_robot_2.png";
import {
  LoginSocialFacebook,
  LoginSocialGoogle,
  LoginSocialMicrosoft,
  LoginSocialApple
} from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  MicrosoftLoginButton,
  AppleLoginButton
} from "react-social-login-buttons";
import toast from "react-hot-toast";
import landingImage from "@assets/landin_robot_2.png";
const redirectUri = window.location.origin + "/login";

const LoginView = ({ path = "home" }) => {
  const { login, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(path, { replace: true });
    }
  }, [isLoggedIn, navigate, path]);

  const handleLogin = (email, password) => {
    login(email, password);
    navigate(path);
  };
  const handleClick = (event) => {
    console.log(event);
    if (event.ctrlKey) {
      navigate("/local-login");
    }
  };

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <div className="flex items-center justify-center gap-2 p-4">
            <h1 className="text-2xl font-bold">Welcome to Mealbot</h1>
          </div>
          <div className="flex justify-center items-center max-w-md">
            <figure className="pointer-events-none object-fit">
              <img src={loginImage} alt="Login" className="rounded-xl" />
            </figure>
          </div>
          <Form onLogin={handleLogin} />
        </div>
      </div>
    </div>
  );
};

export const Form = () => {
  const { socialLogin } = useAuth();

  return (
    <div className="flex flex-col justify-center gap-4 px-10 py-10">
      <LoginSocialGoogle
        client_id={window._env_.GOOGLE_CLIENT_ID || ""}
        redirect_uri={redirectUri}
        scope="openid profile email"
        discoveryDocs="claims_supported"
        isOnlyGetToken={true}
        onResolve={socialLogin}
        onReject={(err) => {
          console.log(err);
          toast.error("Failed social login");
        }}
      >
        <GoogleLoginButton />
      </LoginSocialGoogle>
      <LoginSocialFacebook
        appId={window._env_.FACEBOOK_APP_ID || ""}
        redirect_uri={redirectUri}
        fields="email"
        scope="email"
        isOnlyGetToken={true}
        onResolve={socialLogin}
        onReject={(err) => {
          console.log(err);
          toast.error("Failed social login");
        }}
      >
        <FacebookLoginButton />
      </LoginSocialFacebook>
      <LoginSocialMicrosoft
        client_id={window._env_.MICROSOFT_CLIENT_ID || ""}
        redirect_uri={redirectUri}
        scope="User.Read"
        isOnlyGetToken={true}
        onResolve={socialLogin}
        onReject={(err) => {
          console.log(err);
          toast.error("Failed social login");
        }}
      >
        <MicrosoftLoginButton />
      </LoginSocialMicrosoft>
      <LoginSocialApple
        client_id={window._env_.APPLE_CLIENT_ID || ""}
        redirect_uri={redirectUri}
        scope="name email"
        onResolve={socialLogin}
        onReject={(err) => {
          console.log(err);
          toast.error("Failed social login");
        }}
      >
        <AppleLoginButton />
      </LoginSocialApple>
    </div>
  );
};

export default LoginView;
