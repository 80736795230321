import React, { createContext, useState, ReactNode, ReactElement } from "react";
import { CiApple } from "react-icons/ci";
import ManagePreferences from "@components/Profile/ManagePreferences";
import { FaAppleAlt, FaQuestionCircle } from "react-icons/fa";
import { MdFeedback } from "react-icons/md";
import { RxAvatar } from "react-icons/rx";
import ManagePersonalInformation from "@components/Profile/ManagePersonalInformation";
import { useApi } from "@api/api";
import FaqView from "@views/faq.view";
import { IoChatbubble, IoLockClosed } from "react-icons/io5";

interface NavigationContextValue {
  showSidebar: boolean;
  handleOpenSidebarChange: () => void;
  handlePathNameChange: (name: string) => void;
  currentPathName: string;
  currentComponent: ProfileComponent;
  profileComponents: ProfileComponents;
}

export const NavigationContext = createContext<NavigationContextValue | null>(
  null,
);

interface NavigationProviderProps {
  children: ReactNode;
}

interface ProfileComponent {
  description?: string;
  component?: ReactElement;
  icon: ReactElement;
}

interface ProfileComponents {
  [key: string]: ProfileComponent;
}

export const NavigationProvider = ({ children }: NavigationProviderProps) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [currentPathName, setCurrentPathName] = useState("");
  const { useProfileQuery } = useApi();
  const { data } = useProfileQuery();
  const { email } = data || {};

  const profileComponents: ProfileComponents = {
    "Manage Profile": {
      description: email,
      component: <ManagePersonalInformation />,
      icon: <RxAvatar />,
    },
    "Manage Preferences": {
      description:
        "Juggling food preferences is like being a chef at a zoo, where every animal is a picky eater with a gourmet palate.",
      component: <ManagePreferences />,
      icon: <FaAppleAlt />,
    },
    FAQs: {
      description:
        "We're here to help! Here's our list of most frequently asked questions",
      component: <FaqView />,
      icon: <FaQuestionCircle />,
    },
    Feedback: {
      icon: <MdFeedback />,
    },
    "Focus group form": {
      icon: <IoChatbubble />,
    },
    "Admin Panel": {
      icon: <IoLockClosed />,
    },
  };

  const handleOpenSidebarChange = () => {
    setShowSidebar(!showSidebar);
  };
  const handlePathNameChange = (name: string) => {
    setCurrentPathName(name);
    handleOpenSidebarChange();
  };

  return (
    <NavigationContext.Provider
      value={{
        showSidebar,
        handleOpenSidebarChange,
        currentPathName,
        currentComponent: profileComponents[currentPathName],
        profileComponents,
        handlePathNameChange,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
