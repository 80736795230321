import React, { useEffect, useState } from "react";

type CuisineSelectorProps = {
  selectedCuisines: string[];
  setSelectedCuisines: React.Dispatch<React.SetStateAction<string[]>>;
  options: string[];
  size?: "small" | "large";
};

const CuisineSelector: React.FC<CuisineSelectorProps> = ({
  selectedCuisines = [],
  setSelectedCuisines,
  options,
  size = "small",
}) => {
  const handleSelect = (cuisine: string) => {
    if (cuisine === "Anything" || cuisine === "Surprise me") {
      setSelectedCuisines([cuisine]);
    } else {
      if (
        selectedCuisines.includes("Anything") ||
        selectedCuisines.includes("Surprise me")
      ) {
        setSelectedCuisines([cuisine]);
        return;
      }
      if (selectedCuisines.includes(cuisine)) {
        setSelectedCuisines(selectedCuisines.filter((c) => c !== cuisine));
      } else {
        setSelectedCuisines([...selectedCuisines, cuisine]);
      }
    }
  };

  const sizeClasses =
    size === "small" ? "btn-sm text-sm font-light" : "btn-lg text-3xl";

  return (
    <div className="flex flex-wrap gap-2 items-center justify-center">
      {["Anything", "Surprise me", ...options].map((option) => {
        return (
          <button
            key={option}
            className={`btn ${sizeClasses} ${
              selectedCuisines.includes(option) ? "btn-primary" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              handleSelect(option);
            }}
          >
            {option}
          </button>
        );
      })}
    </div>
  );
};

export default CuisineSelector;
