import { useContext } from "react";
import NewMealContext from "@contexts/NewMealContext";

export const useNewMeal = () => {
  const context = useContext(NewMealContext);
  if (!context) {
    throw new Error("useNewMeal must be used within a NewMealProvider");
  }
  return context;
};
