import React, { ChangeEventHandler, FocusEventHandler, useState } from "react";
import { useApi } from "@api/api";

interface RecipeCommentsProps {
  id: string;
  initialValue: string;
}

export const RecipeComments: React.FC<RecipeCommentsProps> = ({
  id,
  initialValue,
}) => {
  const [comments, setComments] = useState<string>(initialValue);
  const { commentOnRecipeMutation } = useApi();
  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = () => {
    commentOnRecipeMutation.mutate({ id, comments });
  };

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const value = event.target.value.trim();
    if (value !== "") {
      setComments(event.target.value);
    }
  };

  return (
    <>
      <div className="mb-2 font-bold">Comments:</div>
      <textarea
        className="textarea textarea-bordered"
        value={comments}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </>
  );
};
