import { useNavigation } from "@hooks/useNavigation";
import { IoArrowBackOutline } from "react-icons/io5";
import TopCard from "@components/Profile/TopCard";

const Sidebar = () => {
  const {
    showSidebar,
    handleOpenSidebarChange,
    currentPathName,
    currentComponent,
  } = useNavigation();

  return (
    <div className="z-30">
      <div
        className={`top-0 right-0 w-[100vw] bg-neutral-content  pb-10 text-white fixed h-full z-40 overflow-scroll ease-in-out duration-300 ${
          showSidebar ? "translate-x-0 " : "translate-x-full"
        }`}
      >
        {showSidebar && (
          <div className="flex flex-col">
            <div className="flex py-4 px-5 bg-base-100 space-x-8">
              <button
                className="flex text-2xl text-primary cursor-pointer"
                onClick={handleOpenSidebarChange}
              >
                <IoArrowBackOutline />
              </button>

              <p className="text-black font-medium">{currentPathName}</p>
            </div>
            <div className="flex flex-col space-y-4 items-center">
              <TopCard />
              {currentComponent.component}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
