import { useApi } from "@api/api";
import { useNewMeal } from "@hooks/useNewMeal";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { ShareButton } from "@components/ShareButton";
import Lottie from "lottie-react";
import bowlSpinning from "/src/lotties/bowl_spinning.json";
import React, { useEffect, useRef, useState } from "react";
import { CgChevronLeft } from "react-icons/cg";
import RecipeView from "@components/RecipeView";
import FeedbackModal from "@components/FeedbackModal";
import { FaRegStar } from "react-icons/fa6";
import { RecipeComments } from "@components/RecipeComments";
import { StarRating } from "@components/StarRating";
import {tagEvent} from "@components/util";

const FinalRecipeView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mealState } = useNewMeal();
  const { useHowToMakeQuery, saveRecipeMutation, giveFeedback } = useApi();
  const navigate = useNavigate();
  const onBack = () => {
    tagEvent("button_click_rcp_vw", { button_name: "Back" });
    navigate("/meal/select");
  }

  const {
    data: response,
    isLoading,
    isError,
    error,
  } = useHowToMakeQuery(mealState);

  useEffect(() => {
    if (response?.error) {
      toast.error(response.msg);
      if (response.status === 402) navigate("/trialEnded");
      if (response.status === 429) navigate("/slowDown");
    }
  }, [response, navigate]);

  if (isLoading) return <LoadingAnimation />;
  if (error) {
    const msgDetail = JSON.parse(error.message).detail;
    toast.error("Error getting recipe: " + msgDetail, {
      id: "error-getting-recipe",
    });
  }
  if (isError) return <div>Error... {JSON.stringify(error)}</div>;

  const saveRecipe = () => {
    saveRecipeMutation.mutate(response.aii_id, {});
  };

  return (
    <>
      {isModalOpen && (
        <FeedbackModal
          aii_id={response.aii_id}
          onSubmit={(value) => {
            setIsModalOpen(false);
            giveFeedback.mutate(value);
          }}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
      <div className="card">
        <div className="card-body">
          <RecipeView recipe={response.recipe} />
          <RecipeComments
            id={response.aii_id}
            initialValue={response.comments}
          />
          <StarRating id={response.aii_id} initialValue={response.rating} />
        </div>
        <div className="divider">
          <button
            className="btn btn-sm text-xs btn-ghost font-light"
            onClick={() => setIsModalOpen(true)}
          >
            Something off? Give Feedback
          </button>
        </div>

        <div className="card-actions justify-between m-4">
          <button className="btn btn-neutral" onClick={() => {
              tagEvent("recipe_view_go_back", { button_name: "Back" });
              onBack();
            }}>
            <CgChevronLeft />
            Back
          </button>
          <ShareButton id={response.aii_id} />
          <button className="btn btn-neutral mb-2" onClick={() => {
              tagEvent("recipe_view_save", { button_name: "Save" });
              saveRecipe();
            }}>
            <FaRegStar /> Save
          </button>
        </div>
      </div>
    </>
  );
};

export default FinalRecipeView;

const funny_loading_text = [
  "Stirring Up a Pot of Digital Delights",
  "Balancing Flavor Harmonics",
  "Teasing Taste Buds' Patience",
  "Conjuring Culinary Enchantments",
  "Navigating the Spaghetti Code Maze",
  "Marinating in Quantum Marinara",
  "Taming the Wild Spices",
  "Whipping Up a Data Soufflé",
  "Grilling Bits and Bytes to Perfection",
  "Simulating a Chocolate Fountain Rave",
  "Infusing Code with Vanilla Essence",
  "Prepping the Pixelated Produce",
  "Crafting Pixel Pesto",
  "Decrypting Deliciousness Algorithms",
  "Simulating Sizzling Bacon Waves",
  "Conjuring a Byte-sized Banquet",
  "Organizing Quantum Quinoa",
  "Syncing Simulated Sushi Scrolls",
  "Fermenting Fictional Flavors",
  "Roasting Imaginary Ingredients",
  "Sautéing Surreal Spices",
  "Noodling with Noodle Notions",
  "Mixing a Matrix of Mashed Potatoes",
  "Simulating a Chili Con Connectivity",
  "Unleashing the Cookie Dough Byte",
  "Slicing and Dicing Digital Doughnuts",
  "Baking Pixels in a Virtual Oven",
  "Toasting Virtual Toasters",
  "Microwaving Virtual Microwaves",
  "Peeling Potatoes in Pixel Paradise",
  "Whipping Whimsical Whipped Cream",
  "Carving Code Carrots",
  "Wokking with Wok Wonders",
  "Mixing Metaphorical Meatballs",
  "Toasting Theoretical Tofu",
  "Roasting a Pixelated Rooster",
  "Stirring Up a Simulation Storm",
  "Savoring Synthetic Soups",
];

const LoadingAnimation = () => {
  const [text, setText] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const textIndexRef = useRef(0);
  const isVisibleRef = useRef(false);

  const getRandomText = () => {
    const randomIndex = Math.floor(Math.random() * funny_loading_text.length);
    textIndexRef.current = randomIndex;
    return funny_loading_text[randomIndex];
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isVisibleRef.current) {
        setText(getRandomText());
        setIsVisible(true);
        isVisibleRef.current = true;
      } else {
        setIsVisible(false);
        isVisibleRef.current = false;
      }
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="w-64 h-64 mt-32">
        <Lottie animationData={bowlSpinning} />
      </div>
      <p className={`${isVisible ? "fade-in-text" : "fade-out-text"} text-xl`}>
        {text}
      </p>
    </div>
  );
};
