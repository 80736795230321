import { useApi } from "@api/api";
import toast from "react-hot-toast";
import RecipeView from "@components/RecipeView";
import ShortLoading from "@components/ShortLoading";
import { useNavigate } from "react-router-dom";

const SharedRecipeView = () => {
  const { useGetSharedRecipeQuery } = useApi();
  const params = new URLSearchParams(window.location.search);
  const recipe_id = params.get("recipe_id");

  const { data, isLoading, isError, error } =
    useGetSharedRecipeQuery(recipe_id);

  if (!recipe_id) return <>Error we need a recipe id!</>;
  if (isLoading) return <ShortLoading />;
  if (error) {
    const msgDetail = JSON.parse(error.message).detail;
    toast.error("Error getting recipe: " + msgDetail, {
      id: "error-getting-recipe",
    });
  }
  if (isError)
    return (
      <>
        <div>Error... {JSON.stringify(error)}</div>
      </>
    );

  return (
    <div className="flex justify-center">
      <div className="card bg-base-100 shadow-xl max-w-4xl">
        <div className="card-body">
          <RecipeView recipe={data.recipe_model} />
          <JoinMealBot />
        </div>
      </div>
    </div>
  );
};

export default SharedRecipeView;
const JoinMealBot = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-semibold text-gray-800 mb-2">
        Like what you see?
      </h2>
      <p className="text-gray-600 mb-4">
        Come join and try MealBot yourself! It's fun and delicious to make your
        own recipes.
      </p>
      <button onClick={() => navigate("/")} className="btn btn-primary">
        Join Now!
      </button>
    </div>
  );
};
