import { useNewMeal } from "@hooks/useNewMeal";
import { useNavigation } from "@hooks/useNavigation";
import { useApi } from "@api/api";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MealSelector from "@components/Form/MealSelector";
import CounterComponent from "@components/Profile/Preferences/CounterComponent";
import TimeSelector from "@components/Form/TimeSelector";
import StorePickupOption from "@components/Form/StorePickupOption";
import CuisineSelector from "@components/Form/CuisineSelector";
import DebouncedText from "@components/Profile/Preferences/DebouncedText";
import IngredientSelector from "@components/Form/IngredientSelector";
import ShortLoading from "@components/ShortLoading";
import { Alert, Button } from "react-daisyui";
import { tagEvent } from "@components/util";

const NewMealForm = () => {
  const { mealState, setMealState, clearMealOptions } = useNewMeal();
  const { useMealFormMetadataQuery, useProfileQuery } = useApi();
  const navigate = useNavigate();
  const { data: formMetadata, isLoading, isError } = useMealFormMetadataQuery();
  const { data: profile, isLoading: profileLoading } = useProfileQuery();
  const { handlePathNameChange } = useNavigation();

  const trialEndingSoon =
    profile?.subscription?.kind === "trial" &&
    profile?.subscription?.days_left < 2;

  useEffect(() => {
    const hasOnboarded = profileLoading ? true : profile.has_on_boarded;
    if (!hasOnboarded) {
      navigate("/on-boarding");
    }
  }, [profileLoading, navigate, profile]);

  useEffect(() => {
    if (formMetadata) {
      setMealState(formMetadata.default);
    }
  }, [formMetadata, setMealState]);

  if (isLoading) return <ShortLoading />;
  if (isError) return <div>Error... </div>;

  const handleSubmit = async (event) => {
    event.preventDefault();
    clearMealOptions();
    navigate("/meal/select");
  };

  function setIngredientList(newList, clearText) {
    if (clearText) {
      setMealState({
        ...mealState,
        ingredients_list: newList,
        ingredients: "",
      });
    } else {
      setMealState({
        ...mealState,
        ingredients_list: newList,
      });
    }
  }

  function setMustUseIngredientList(newList, clearText) {
    if (clearText) {
      setMealState({
        ...mealState,
        must_use_ingredients_list: newList,
        must_use_ingredients: "",
      });
    } else {
      setMealState({
        ...mealState,
        must_use_ingredients_list: newList,
      });
    }
  }

  function setIngredientText(newText) {
    setMealState({
      ...mealState,
      ingredients: newText,
    });
  }

  function setMustUseIngredientText(newText) {
    setMealState({
      ...mealState,
      must_use_ingredients: newText,
    });
  }

  function updateMealState(field, value) {
    setMealState({
      ...mealState,
      [field]: value,
    });
  }
  const dayLeft = Math.round(profile?.subscription?.days_left || 1);
  const isTrialEnded =
    profile?.subscription?.kind === "trial" &&
    profile?.subscription?.days_left <= 0;
  const trialEndedText = isTrialEnded
    ? "Trial ended you must subscribe to use"
    : `Trial ending in less than ${dayLeft} day${dayLeft > 1 ? "s" : ""}`;
  const noIngredients = mealState.ingredients_list.length === 0;

  return (
    <>
      <div className="flex justify-center items-center bg-base-300">
        <div className="p-4 space-y-4 max-w-xl flex flex-col items-center">
          <form className="flex flex-col items-center" onSubmit={handleSubmit}>
            {trialEndingSoon && (
              <Alert status="warning" className="flex justify-between">
                <div>{trialEndedText}</div>
                <Button
                  size="sm"
                  onClick={() => handlePathNameChange("Manage Profile")}
                >
                  Profile
                </Button>
              </Alert>
            )}
            <label className="label w-full">
              <span className="label-text text-xl ">Which Meal?</span>
            </label>
            <MealSelector
              selectedMeal={mealState.meal_name}
              setSelectedMeal={(value) => {
                tagEvent("meal_selected", { meal_name: value });
                updateMealState("meal_name", value);
              }}
            />
            <label className="label block mt-4 w-full">
              <span className="label-text text-xl">
                Who are we cooking for?
              </span>
            </label>
            <CounterComponent
              adultCount={mealState.number_of_adults}
              setAdultCount={(value) => {
                tagEvent("adult_count_updated", { adult_count: value });
                updateMealState("number_of_adults", value);
              }}
              kidCount={mealState.number_of_kids}
              setKidCount={(value) => {
                tagEvent("kid_count_updated", { kid_count: value });
                updateMealState("number_of_kids", value);
              }}
            />
            <IngredientSelector
              label="What can we cook with?"
              ingredientList={mealState.ingredients_list}
              updateList={setIngredientList}
              ingredientText={mealState.ingredients}
              updateText={setIngredientText}
            />
            <IngredientSelector
              label="Any food we have to use?"
              ingredientList={mealState.must_use_ingredients_list}
              updateList={setMustUseIngredientList}
              ingredientText={mealState.must_use_ingredients}
              updateText={setMustUseIngredientText}
            />
            <label className="label block mt-4 w-full">
              <span className="label-text text-xl">
                How much time do we have?
              </span>
            </label>
            <TimeSelector
              selectedTime={mealState.minutes_available}
              setSelectedTime={(value) => {
                tagEvent("time_selected", { minutes_available: value });
                updateMealState("minutes_available", value);
              }}
              size={"small"}
            />
            <label className="label block mt-4 w-full">
              <span className="label-text text-xl">
                Can we go to the store?
              </span>
            </label>
            <StorePickupOption
              canPickup={mealState.can_goto_store}
              setCanPickup={(value) => {
                tagEvent("can_goto_store_updated", { can_goto_store: value });
                updateMealState("can_goto_store", value);
              }}
              size="small"
            />

            <label className="label block mt-4 w-full">
              <span className="label-text text-xl">What type of cuisine?</span>
            </label>
            <CuisineSelector
              selectedCuisines={mealState.cuisines}
              setSelectedCuisines={(value) => {
                tagEvent("cuisines_selected", { cuisines: value });
                updateMealState("cuisines", value);
              }}
              options={formMetadata.cuisine_options || []}
            />
            <label className="label block mt-4 w-full">
              <span className="label-text text-xl">Any special requests?</span>
            </label>
            <DebouncedText
              initialValue={mealState.special_requests}
              onSave={(value) => {
                tagEvent("special_requests_updated", {
                  special_requests: value,
                });
                updateMealState("special_requests", value);
              }}
              placeholder="Only liquid diet, no meat, etc."
            />
            <div className="form-control mt-8">
              <button
                className="btn btn-secondary btn-large text-xl text-primary-content"
                disabled={noIngredients}
                onClick={() => {
                  tagEvent("button_clicked", {
                    button_name: "Give me some ideas!",
                  });
                }}
              >
                Give me some ideas!
              </button>
              {noIngredients && (
                <span className="text-sm font-light mt-1">
                  Enter some ingredients above to get ideas
                </span>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewMealForm;
