import React, { useState } from "react";

type SimpleTextInputProps = {
  initialValue: string;
  onSave: (value: string) => void;
  placeholder?: string;
};

const SimpleTextInput: React.FC<SimpleTextInputProps> = ({
  initialValue,
  onSave,
  placeholder = "",
}) => {
  const [value, setValue] = useState(initialValue);
  return (
    <div className="w-full">
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => onSave(value)}
        className="input w-full bg-neutral-content/60"
        placeholder={placeholder}
      />
    </div>
  );
};

export default SimpleTextInput;
