import { useEffect, useState, useMemo } from "react";
import { useAdminApi, CreatePromotionCodeRequest } from "@api/adminApi";
import ShortLoading from "@components/ShortLoading";
import { Table, Button } from "react-daisyui";
import SortableOption from "@components/Admin/SortableOption";
import { sortData, options } from "@components/Admin/util";
import AIInteractionModal from "@components/AIInteractionModal";
import TimestampFormatter from "@components/TimeStampFormatter";
import SearchBar from "@components/SearchBar";
import DiscountCodeModal from "@components/DiscountCodeModal";

const PromotionCodeSection = () => {
    const [promotionCodeSortConfig, setPromotionCodeSortConfig] = useState({
      key: "timestamp",
      direction: "desc",
    });
    const [filters, setFilters] = useState(options);
    const [creatingDiscountCode, setCreatingDiscountCode] = useState(false);
    const [loadingPromotionCode, setLoadingPromotionCode] = useState(false);
    const [usersList, setUsersList] = useState([]);

    const { createPromotionCode, useGetPromoCodeInformation } = useAdminApi();

    const { data: promoCodeInfo, isLoading: infoIsLoading } = useGetPromoCodeInformation();

    const handleSortChange = (key, direction) => {
        setPromotionCodeSortConfig({ key, direction });
    }

    const changeFilter = (filterName, newValue) => {
        setFilters({
          ...filters,
          [filterName]: { ...filters[filterName], value: newValue },
        });
      };

    const initPromotionCodeResponse = useGetPromoCodeInformation();
    const { data: initObject, isLoading: initIsLoading } = initPromotionCodeResponse;

    return (
        <>
        <div className="flex items-center align-middle relative">
            <div className="mr-2 flex items-center align-middle ml-auto relative">
                <SearchBar onSearch={(search) => changeFilter("search", search)} />
                {!initIsLoading && (
                    <Button 
                        className="ml-2 btn-sm btn-primary"
                        onClick={() => setCreatingDiscountCode(true)}
                    >Create New</Button>
                )}
                {initIsLoading && (
                    <Button 
                        className="ml-2 btn-sm btn-primary"
                    >
                        <span className='loading loading-spinner loading-sm'></span>
                    </Button>
                )}
            </div>
        </div>
        <hr className="my-4" />
        {creatingDiscountCode && (
            <DiscountCodeModal 
                onSubmit={() => setCreatingDiscountCode(false)}
                onCancel={() => setCreatingDiscountCode(false)}
                userArray={initObject["users"]}
                isLoading={initIsLoading}
            />
        )}
        </>
    );
};

export default PromotionCodeSection;
