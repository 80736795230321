import React from "react";
import { IconType } from "react-icons";

interface Props {
  count: number;
  Icon: IconType;
}

const IconCounter: React.FC<Props> = ({ count, Icon }) => {
  const wholeCount = Math.floor(count);
  const fraction = count % 1;

  return (
    <>
      {Array.from({ length: wholeCount }, (_, index) => (
        <Icon key={index} className="text-lg w-6 h-6" />
      ))}
      {fraction > 0 && (
        <div className="relative inline-block w-4 h-4 overflow-hidden">
          <Icon className="absolute w-full h-full" />
        </div>
      )}
    </>
  );
};

export default IconCounter;
