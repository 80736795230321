import React, { useState, KeyboardEvent } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CgClose, CgMathPlus } from "react-icons/cg";
import { useApi } from "@api/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

type IngredientSelectorProps = {
  label?: string;
  placeholder?: string;
  ingredientList: string[];
  updateList: (newList: string[], shouldAdd?: boolean) => void;
  ingredientText: string;
  updateText: (text: string) => void;
  badgeKind?: string;
};

const IngredientSelector: React.FC<IngredientSelectorProps> = ({
  label = "What can we cook with?",
  placeholder = "One or many ingredients",
  ingredientList = [],
  updateList = () => {},
  ingredientText = "",
  updateText = () => {},
  badgeKind = "primary",
}) => {
  const { convertIngredients } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const removeIngredient = (index: number) =>
    updateList(ingredientList.filter((_, i) => i !== index));
  const clearIngredients = () => updateList([]);

  async function updateIngredientsList(e: React.FormEvent | KeyboardEvent) {
    e.preventDefault();
    setLoading(true);
    const newIngredients = await convertIngredients(
      ingredientText,
      ingredientList,
    );
    if (newIngredients.error) {
      toast.error(newIngredients.msg);
      if (newIngredients.status === 402) navigate("/trialEnded");
      if (newIngredients.status === 429) navigate("/slowDown");
    } else updateList(newIngredients, true);
    setLoading(false);
  }

  const ingredientTextEmpty = ingredientText.trim().length === 0;

  return (
    <div className="form-control w-full mt-2">
      {label.length > 0 && (
        <label className="label block">
          <span className="label-text text-xl">{label}</span>
        </label>
      )}
      <div className="flex flex-wrap items-center space-x-1 font-light">
        <AnimatePresence>
          {(ingredientList || []).map((ingredient, index) => {
            return (
              <motion.div
                key={ingredient}
                className={`badge badge-${badgeKind} p-3 m-1`}
                onClick={() => removeIngredient(index)}
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
              >
                {ingredient}
                <CgClose />
              </motion.div>
            );
          })}
          {ingredientList.length > 0 && (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
              key={"ingredient-clear"}
              className="badge badge-secondary p-3 m-1"
              onClick={() => clearIngredients()}
            >
              Clear
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="flex justify-center">
        <div className="join mt-2">
          <input
            className="input block join-item"
            placeholder={placeholder}
            value={ingredientText}
            onChange={(e) => {
              updateText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                return updateIngredientsList(e);
              }
            }}
            disabled={loading}
          />
          <button
            className="btn join-item"
            onClick={updateIngredientsList}
            disabled={loading || ingredientTextEmpty}
          >
            <CgMathPlus />
          </button>
        </div>
      </div>
    </div>
  );
};

export default IngredientSelector;
