import React, { ReactElement } from "react";
import { Alert } from "react-daisyui";
import { FiChevronRight } from "react-icons/fi";
import { FaChessKing, FaRegStar, FaUser } from "react-icons/fa";
import useNavigation from "@hooks/useNavigation";
import Avatar from "@components/Profile/Avatar";

const icons: { [key: string]: ReactElement } = {
  admin: <FaChessKing />,
  premiumUser: <FaRegStar />,
  user: <FaUser />,
};
const EmailAndRole: React.FC<{ email: string; role: string; type: string }> = ({
  email,
  role,
  type,
}) => {
  const { handlePathNameChange } = useNavigation();
  return (
    <>
      {type === "profile" && (
        <div className="flex flex-col space-y-2 justify-center">
          <div className="flex items-center mb-2">
            <div className="font-medium w-5/12">Primary Email:</div>
            <div className="w-7/12">{email}</div>
          </div>
          {role === "admin" && (
            <Alert status="info">You are an admin {icons[role]}</Alert>
          )}
        </div>
      )}
      {type === "button" && (
        <div
          className="flex justify-between items-center"
          onClick={() => handlePathNameChange("Manage Profile")}
        >
          <div className="flex space-x-4 items-center">
            <Avatar icon={icons[role]} />
            <div className="flex flex-col mb-2">
              <p className="font-medium text-lg">Profile & Subscription</p>
              <p className="text-gray-600">{email}</p>
            </div>
          </div>
          <FiChevronRight className="text-neutral-content" />
        </div>
      )}
    </>
  );
};

export default EmailAndRole;
