import { PreferencesEditor } from "@components/Profile/Preferences/PreferencesEditor";
import { useApi } from "@api/api";

const ManagePreferences = () => {
  const { useProfileQuery } = useApi();
  const { data } = useProfileQuery();
  const { preferences } = data || {};

  return <PreferencesEditor preferences={preferences} />;
};

export default ManagePreferences;
