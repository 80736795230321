import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SearchableSelect = ({ data, isLoading, fieldName, maxResults, otherOption }) => {
    const [input, setInput] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);


    useEffect(() => {
        if (data && otherOption && input.toLowerCase().trim() != otherOption.toLowerCase().trim()) { 
            const filtered = data.filter(item => 
                item[fieldName].toLowerCase().includes(input.toLowerCase())
            ).slice(0, maxResults);
            setFilteredData(filtered);
        }
        setShowDropdown(filteredData.length > 0);
        adjustHighlightedIndex(highlightedIndex); // Call this function to adjust the index
    }, [input, data, fieldName, maxResults, highlightedIndex]); // Add highlightedIndex as a dependency

    const adjustHighlightedIndex = (currentIndex) => {
        if (currentIndex < 0 || currentIndex >= filteredData.length) {
            setHighlightedIndex(-1);
        }else{
            setHighlightedIndex(currentIndex);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
        setShowDropdown(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (isFocused) {
                setIsFocused(false);
                setShowDropdown(false);
            }
            if (showDropdown) {
                setIsFocused(false);
                setShowDropdown(false);
            }
        }, 150);
    };    

    const handleSelect = (value) => {
        setInput(value);
        setIsFocused(false); // Update focus state here
        setShowDropdown(false);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && filteredData.length > 0) {
            if (highlightedIndex >= 0) {
                handleSelect(filteredData[highlightedIndex][fieldName]);
            }
        } else if (e.key === 'ArrowDown') {
            adjustHighlightedIndex((highlightedIndex + 1) % filteredData.length);
        } else if (e.key === 'ArrowUp') {
            adjustHighlightedIndex((highlightedIndex - 1 + filteredData.length) % filteredData.length);
        } else {
            setIsFocused(true);
        }
    };

    return (
        <div className="relative" onBlur={handleBlur}>
            <input
                type="text"
                className="input input-bordered w-full"
                placeholder="Search..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onFocus={handleFocus}
                onKeyDown={handleKeyPress}
            />
            {isLoading && <span className='loading loading-spinner loading-sm'></span>}
            {showDropdown && isFocused && !isLoading && (
                <div className="absolute w-full bg-white border border-gray-200 rounded-md shadow-lg max-h-48 overflow-y-scroll" style={{ zIndex: 100000 }}>
                {input === '' && otherOption && (
                    <div
                        className={`p-2 hover:bg-gray-100 cursor-pointer ${highlightedIndex === -1 ? 'bg-gray-200' : ''}`}
                        onClick={() => handleSelect(otherOption)}
                    >
                        {otherOption}
                    </div>
                )}
                {filteredData.map((item, index) => (
                    <div 
                        key={index}
                        className={`p-2 hover:bg-gray-100 cursor-pointer ${index === highlightedIndex ? 'bg-gray-200' : ''}`}
                        onClick={() => handleSelect(item[fieldName])}
                    >
                        {item[fieldName]}
                    </div>
                ))}
            </div>
            )}
        </div>
    );
};

SearchableSelect.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fieldName: PropTypes.string.isRequired,
    maxResults: PropTypes.number,
    otherOption: PropTypes.string,
};

export default SearchableSelect;
