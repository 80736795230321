import React, { useState } from "react";
import { useApi } from "@api/api";

const SubscribeView: React.FC = () => {
  const [plan, setPlan] = useState<"month_to_month" | "annual">("annual");
  const { createCheckoutSession } = useApi();

  const handleOrder = async () => {
    try {
      const { sessionUrl } = await createCheckoutSession(plan);
      window.location.assign(sessionUrl);
    } catch (error) {
      console.error("Error creating checkout session: ", error);
    }
  };

  const monthlyCost = 9;
  const annualCost = 5;
  const annualSavings = ((monthlyCost - annualCost) * 12).toFixed(0);
  const annualSavingsPercentage = (
    (1 - annualCost / monthlyCost) *
    100
  ).toFixed(0);

  return (
    <div className="flex flex-col items-center justify-center h-screen p-4">
      <div className="text-center">
        <h2 className="text-3xl font-bold mb-3">Thank You from Mealbot!</h2>
        <p className="text-sm mb-2">
          Did you know? Cooking just one extra meal at home a month saves you
          more than the subscription.
        </p>

        <p className="text-lg mb-5">Select the plan that's right for you:</p>
        <div className="flex flex-col items-center space-y-4">
          <button
            onClick={() => setPlan("month_to_month")}
            className={`btn btn-lg ${
              plan === "month_to_month" ? "btn-accent" : "btn-outline"
            } mb-2`}
          >
            Monthly - $8.99/mo
          </button>
          <button
            onClick={() => setPlan("annual")}
            className={`btn btn-lg ${
              plan === "annual" ? "btn-accent" : "btn-outline"
            }`}
          >
            Annual - $4.99/mo
          </button>
          {plan === "month_to_month" && (
            <div className="text-sm">
              <p>Go Annual and save ${annualSavings} a year</p>
              <p>that's a {annualSavingsPercentage}% reduction!</p>
            </div>
          )}
        </div>
        <button onClick={handleOrder} className="btn btn-primary btn-lg mt-8">
          Unleash the Chef!
        </button>
        <p className="text-lg mt-6 mb-8">
          Enjoy endless recipes and personalized meal planning with Mealbot.
        </p>
        <p>Your support helps us grow and improve!</p>
      </div>
    </div>
  );
};

export default SubscribeView;
