import React from "react";
import { capitalizeFirstLetter } from "@components/util";

type SpiceSelectorProps = {
  selectedSpiceLevel: "barebones" | "typical" | "exotic";
  setSelectedSpiceLevel: React.Dispatch<
    React.SetStateAction<"barebones" | "typical" | "exotic">
  >;
  size?: "small" | "large";
};

const DefaultSpiceSelector: React.FC<SpiceSelectorProps> = ({
  selectedSpiceLevel,
  setSelectedSpiceLevel,
  size = "small",
}) => {
  const spiceLevels: ("barebones" | "typical" | "exotic")[] = [
    "barebones",
    "typical",
    "exotic",
  ];

  const spiceLevelEmojis: {
    [key in "barebones" | "typical" | "exotic"]: string;
  } = {
    barebones: "🧂", // Salt shaker for basic
    typical: "🍲", // Pot of food for standard
    exotic: "🌶️", // Hot pepper for exotic
  };

  const handleSelect = (level: "barebones" | "typical" | "exotic") => {
    setSelectedSpiceLevel(level);
  };

  const sizeClasses =
    size === "small" ? "btn-sm text-lg font-light" : "btn-lg text-3xl";

  return (
    <div className="flex flex-wrap gap-2 items-center justify-center">
      {spiceLevels.map((level) => (
        <button
          key={level}
          className={`btn ${sizeClasses} ${
            selectedSpiceLevel === level ? "btn-primary" : ""
          }`}
          onClick={() => handleSelect(level)}
        >
          {spiceLevelEmojis[level]} {capitalizeFirstLetter(level)}
        </button>
      ))}
    </div>
  );
};

export default DefaultSpiceSelector;
