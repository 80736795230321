import React from "react";
import { FiChevronRight } from "react-icons/fi";
import Avatar from "@components/Profile/Avatar";
import useNavigation from "@hooks/useNavigation";

interface PreferenceButtonProps {
  label: string;
  type?: string;
  href?: string;
}

const PreferenceButton: React.FC<PreferenceButtonProps> = ({
  label,
  type = "",
  href = "",
}) => {
  const { handlePathNameChange, profileComponents } = useNavigation();
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (type === "link") {
      window.open(href, "_blank");
    } else {
      handlePathNameChange(label);
    }
  };

  return (
    <div
      className="flex items-center m-1 justify-between"
      onClick={handleClick}
    >
      <div className="flex items-center space-x-2">
        <Avatar icon={profileComponents[label].icon}></Avatar>
        <div className="font-medium">{label}</div>
      </div>
      <FiChevronRight className="text-neutral-content" />
    </div>
  );
};

export default PreferenceButton;
