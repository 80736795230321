import React, { useState } from "react";
import { MdCheckCircle, MdOutlineRadioButtonUnchecked } from "react-icons/md";
import ConfettiExplosion from "react-confetti-explosion";
const InstructionList: React.FC<{
  instructions: { step: string; duration: string }[];
}> = ({ instructions }) => {
  const [selectedInstructions, setSelectedInstructions] = useState<Set<number>>(
    new Set(),
  );

  const isExploding = selectedInstructions.size === instructions.length;

  // Handler for checkbox change
  const handleCheckboxChange = (instructionId: number) => {
    setSelectedInstructions((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(instructionId)) {
        newSet.delete(instructionId);
      } else {
        newSet.add(instructionId);
      }
      return newSet;
    });
  };

  return (
    <>
      <div className="flex w-full items-center justify-center">
        {isExploding && (
          <ConfettiExplosion
            force={0.8}
            duration={3000}
            particleCount={300}
            width={1000}
          />
        )}
      </div>
      {instructions.map((instruction, index) => (
        <div
          key={index}
          className="flex w-full items-center mb-2"
          onClick={() => handleCheckboxChange(index)}
        >
          <div className="w-1/12">
            <CircleCheckbox checked={selectedInstructions.has(index)} />
          </div>
          <div
            className={
              "w-9/12 ml-2 mr-1 " +
              (selectedInstructions.has(index) ? "line-through" : "")
            }
          >
            <span className="">{instruction.step}</span>
          </div>
          <div
            className={
              "w-2/12 text-right " +
              (selectedInstructions.has(index) ? "line-through" : "")
            }
          >
            <span className="">{instruction.duration}</span>
          </div>
        </div>
      ))}
    </>
  );
};

export default InstructionList;

const CircleCheckbox: React.FC<{ checked: boolean }> = ({ checked }) => {
  if (checked) {
    return <MdCheckCircle size={30} color="green" />;
  }
  return <MdOutlineRadioButtonUnchecked size={30} />;
};
