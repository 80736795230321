import React from "react";

type StorePickupOptionProps = {
  canPickup: boolean;
  setCanPickup: React.Dispatch<React.SetStateAction<boolean>>;
  size?: "small" | "large";
};

const StorePickupOption: React.FC<StorePickupOptionProps> = ({
  canPickup,
  setCanPickup,
  size = "large",
}) => {
  const handleSelect = (option: boolean) => {
    setCanPickup(option);
  };

  const sizeClasses =
    size === "small" ? "btn-sm font-light" : "btn-lg text-2xl";

  return (
    <div className="flex flex-wrap items-center justify-center join">
      <button
        className={`btn join-item ${sizeClasses} ${
          canPickup ? "btn-primary" : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          handleSelect(true);
        }}
      >
        Yes
      </button>
      <button
        className={`btn join-item ${sizeClasses} ${
          !canPickup ? "btn-primary" : ""
        }`}
        onClick={(e) => {
          e.preventDefault();
          handleSelect(false);
        }}
      >
        No Time!
      </button>
    </div>
  );
};

export default StorePickupOption;
