import React, { useEffect, useState } from "react";
import { Steps } from "react-daisyui";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { useApi } from "@api/api";
import AllergyOptions from "@components/Profile/Preferences/AllergyOptions";
import DietOptions from "@components/Profile/Preferences/DietOptions";
import CounterComponent from "@components/Profile/Preferences/CounterComponent";
import {tagEvent} from "@components/util";
const steps = ["People", "Diet", "Allergies"];

const OnBoardingView: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [adultCount, setAdultCount] = useState(1.0);
  const [kidCount, setKidCount] = useState(1.0);
  const [selectedDiet, setSelectedDiet] = useState("anything");
  const [selectedAllergies, setSelectedAllergies] = useState([]);
  const currentStepIndex = steps.indexOf(currentStep);
  const { onboardingMutation, useProfileQuery } = useApi();
  const { data: profile } = useProfileQuery();

  useEffect(() => {
    if (profile?.is_onboarded) {
      tagEvent("onboarding_started");
      navigate("/meal/new");
    }
  }, [profile]);

  const onBoard = () => {
  tagEvent("onboarding_skipped_directly", { skipped_step: currentStep });
  onboardingMutation.mutate(
    {
      number_of_adults: adultCount,
      number_of_kids: kidCount,
      preferred_diet: selectedDiet,
      allergies: selectedAllergies,
    },
    {
      onSuccess: () => {
        tagEvent("onboarding_completed");
        navigate("/meal/new");
        },
      },
    );
  };

  const handleNext = () => {
    if (currentStepIndex < steps.length - 1){
    tagEvent("onboarding_step_completed", { step: currentStep });
    setCurrentStep(steps[currentStepIndex + 1]);
  } else {
    onBoard();
    }
  };

  const handleBack = () => {
  if (currentStepIndex > 0) {
    tagEvent("onboarding_step_back", { current_step: currentStep });
    setCurrentStep(steps[currentStepIndex - 1]);
    }
  };

  const currentStepContent = () => {
    switch (currentStep) {
      case "People":
        return (
          <div className="flex flex-col space-y-4 max-w-md">
            <div className="text-xl text-center font-bold">
              How many people?
            </div>
            <CounterComponent
              adultCount={adultCount}
              setAdultCount={setAdultCount}
              kidCount={kidCount}
              setKidCount={setKidCount}
            />
          </div>
        );
      case "Diet":
        return (
          <div className="flex flex-col space-y-4">
            <div className="text-xl font-bold text-center">
              Choose Your Chow!
            </div>
            <DietOptions
              selectedDiet={selectedDiet}
              setSelectedDiet={setSelectedDiet}
            />
          </div>
        );
      case "Allergies":
        return (
          <div>
            <div className="m-8 text-xl font-bold text-center">
              Any allergies?
            </div>
            <AllergyOptions
              selectedAllergies={selectedAllergies}
              setSelectedAllergies={setSelectedAllergies}
            />
          </div>
        );
      default:
        return null;
    }
  };
  const onLastStep = currentStepIndex === steps.length - 1;

  return (
    <div className="flex items-center justify-center h-full bg-base-100 mt-32">
      <div className="">
        <div className="flex flex-col justify-between w-full h-1/2 mt-4">
          <h1 id='welcome-page' className="text-2xl font-bold text-center">Onboarding</h1>
          <p className="text-lg text-center">Let's get to know each other!</p>
          <StepView currentStep={currentStep} />
        </div>
        <div className="flex items-center justify-center w-full min-h-96">
          {currentStepContent()}
        </div>

        <div className="mt-2 font-light text-center">
          No pressure, you can change these later.
        </div>
        <div className="flex justify-between w-full p-4">
          <button
            onClick={handleBack}
            className="btn btn-primary"
            disabled={currentStepIndex === 0}
          >
            <CgChevronLeft /> Back
          </button>
          <button
            onClick={onBoard}
            className="btn btn-ghost font-light text-s underline"
          >
            Skip
          </button>
          <button onClick={handleNext} className="btn btn-primary">
            {onLastStep ? (
              "Lets Eat!"
            ) : (
              <>
                Next
                <CgChevronRight />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingView;
const StepView: React.FC<{ currentStep: string }> = ({ currentStep }) => {
  const currentStepIndex = steps.indexOf(currentStep);
  return (
    <Steps className="mt-4">
      {steps.map((step, index) => (
        <Steps.Step
          key={step}
          color={index <= currentStepIndex ? "primary" : undefined}
        >
          {step}
        </Steps.Step>
      ))}
    </Steps>
  );
};
