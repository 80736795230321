import React, { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import { Button } from "react-daisyui";

import ErrorBot from "@assets/error-bot.svg";
import { captureException } from "@sentry/react";

const errorMessages = [
  "Oops! Looks like our chef spilled the soup. We're cleaning up and will be back to cooking in no time!",
  "Well, this is rare... and we don't mean steak! Something went wrong, but we're grilling the problem to get it fixed.",
  "404: Recipe Not Found. We’re missing some ingredients for this page. Our Mealbot chefs are on a hunt to find them!",
  "Error! It seems like our pasta got overcooked. We're boiling a new batch right away!",
  "Uh-oh, too many cooks in the kitchen! Something went haywire, but we're stirring the pot to fix it.",
  "Our Mealbot tried a new recipe and it didn’t pan out. Give us a moment to whip up something delicious!",
  "This page must've been a secret ingredient. It’s so secret, even we can’t find it. We're onto fixing that!",
  "We're experiencing a kitchen nightmare! Gordon Ramsay would not be pleased. Please bear with us as we fix this culinary mishap.",
  "This page is still marinating. Looks like it needs a bit more time to cook up. Check back soon!",
  "Sizzle! Our servers got too hot and something fried. We're cooling them down and will be back up shortly!",
];
function getRandomErrorMessage() {
  const randomIndex = Math.floor(Math.random() * errorMessages.length);
  return errorMessages[randomIndex];
}

const ErrorPage: React.FC = () => {
  const error: any = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    captureException(error, { level: "fatal" });
    console.log(error);
  }, [error]);

  return (
    <div className="p-2 flex justify-center">
      <div className="flex flex-col justify-center items-center max-w-md min-h-96 h-screen bg-base-200 rounded">
        <h1 className="text-6xl font-bold mt-2">Error</h1>
        <p className=" text-lg m-6 text-center">{getRandomErrorMessage()}</p>
        <Button color="primary" size="lg" onClick={() => navigate("/meal/new")}>
          Go Home
        </Button>
        <img src={ErrorBot} alt="Error bot" className="m-4 max-w-sm" />
      </div>
    </div>
  );
};

export default ErrorPage;
