import React from "react";

type CuisineSelectorProps = {
  selectedCuisines: string[];
  setSelectedCuisines: React.Dispatch<React.SetStateAction<string[]>>;
  size?: "small" | "large";
};

const CuisinesSelector: React.FC<CuisineSelectorProps> = ({
  selectedCuisines,
  setSelectedCuisines,
  size = "small",
}) => {
  const cuisines: string[] = [
    "American",
    "Italian",
    "Mexican",
    "Chinese",
    "Japanese",
    "Indian",
    "Thai",
    "French",
    "Mediterranean",
    "Middle Eastern",
    "Southern",
    "Greek",
    "Spanish",
    "German",
    "Moroccan",
    "Korean",
    "Caribbean",
    "Irish",
    "Vietnamese",
  ];

  const handleSelect = (cuisine: string) => {
    if (selectedCuisines.includes(cuisine)) {
      setSelectedCuisines(selectedCuisines.filter((item) => item !== cuisine));
    } else {
      setSelectedCuisines([...selectedCuisines, cuisine]);
    }
  };

  const sizeClasses =
    size === "small"
      ? "btn-sm text-lg font-light"
      : "btn-lg text-3xl font-bold";

  return (
    <div>
      <div className="flex flex-wrap gap-2 items-center justify-center">
        {cuisines.map((cuisine) => (
          <button
            key={cuisine}
            className={`btn ${sizeClasses} ${
              selectedCuisines.includes(cuisine) ? "btn-primary" : ""
            }`}
            onClick={() => handleSelect(cuisine)}
          >
            {cuisine}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CuisinesSelector;
