import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const SortableOption = ({ title, id, onSortChange, activeSortKey }) => {
    const [sortDirection, setSortDirection] = useState(null);

    const toggleSortDirection = () => {
        const newDirection = sortDirection === 'desc' ? 'asc' : 'desc';
        setSortDirection(newDirection);
        onSortChange(id, newDirection);
    };

    useEffect(() => {
        if (id !== activeSortKey) {
            setSortDirection(null); // Reset sort direction when the sort key changes
        }
    }, [activeSortKey, id]);

    return (
        <button onClick={toggleSortDirection} className="sortable-option">
            {title}
            {id === activeSortKey && sortDirection && (
                <span className={`sort-indicator ${sortDirection}`}>
                    {sortDirection === 'asc' ? '↑' : '↓'}
                </span>
            )}
        </button>
    );
};

SortableOption.propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onSortChange: PropTypes.func.isRequired,
    activeSortKey: PropTypes.string.isRequired
};

export default SortableOption;
