import { useApi } from "@api/api";
import EmailAndRole from "@components/Profile/EmailAndRole";
import SubscriptionInfo from "@components/Profile/SubscriptionInfo";
import { DangerZone } from "@components/Profile/DangerZone";

const ManagePersonalInformation = () => {
  const { useProfileQuery } = useApi();
  const { data } = useProfileQuery();
  const { subscription, email, role } = data || {};

  return (
    <div className="flex flex-col space-y-4 p-4 bg-neutral-content">
      <div className="rounded-lg p-3 bg-base-100 text-black shadow space-y-2">
        <p className="text-medium font-bold">Account Information</p>
        <EmailAndRole email={email} role={role} type={"profile"} />
      </div>

      <div className="rounded-lg p-3 bg-base-100 text-black shadow space-y-2">
        <p className="text-medium font-bold">Subscription Information</p>
        <SubscriptionInfo {...subscription} />
      </div>

      <div className="rounded-lg p-3 bg-base-100  shadow space-y-2">
        <p className="text-medium font-bold text-error">Danger Zone</p>
        <DangerZone />
      </div>
    </div>
  );
};

export default ManagePersonalInformation;
