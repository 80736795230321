import { useNavigate } from "react-router-dom";
import { ElementType } from "react";

interface PageButtonProps {
  path: string;
  icon?: ElementType;
  label?: string;
  className?: string;
}

export function PageButton({
  path,
  icon: Icon,
  label,
  className,
}: PageButtonProps) {
  const navigate = useNavigate();

  const buttonClasses = `btn btn-block ${className || ""}`.trim();

  return (
    <button className={buttonClasses} onClick={() => navigate(path)}>
      {label && <p className="text-xl font-bold">{label}</p>}
      {Icon && <Icon className="text-4xl" />}
    </button>
  );
}
