import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { RiAccountCircleFill } from "react-icons/ri";
import useAuth from "@hooks/useAuth";
import GoogleLoginComponent from "@components/GoogleLoginComponent";
import loginImage from "@assets/landin_robot_2.png";

const ManualLoginView = ({ path = "home" }) => {
  const { login, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(path, { replace: true });
    }
  }, [isLoggedIn]);

  const handleLogin = (email, password) => {
    login(email, password);
    navigate(path);
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-base-200 rounded">
      <div className="m-4 min-h-[50vh] w-full max-w-sm lg:max-w-4xl">
        <Logo />
        <Form onLogin={handleLogin} />
      </div>
    </div>
  );
};

export const Logo = () => {
  return (
    <div className="flex items-center justify-center gap-2 p-8">
      <RiAccountCircleFill />
      <h1 className="text-lg font-bold">Login to your account</h1>
    </div>
  );
};

export const Form = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    onLogin(email, password);
  };
  return (
    <div className="grid bg-base-100 lg:aspect-[2/1] lg:grid-cols-2 rounded">
      <figure className="pointer-events-none bg-base-300 object-cover max-lg:hidden">
        <img src={loginImage} alt="Login" className="h-full" />
      </figure>
      <form
        className="flex flex-col justify-center gap-4 px-10 py-10 lg:px-16"
        onSubmit={handleSubmit}
      >
        <div className="form-control">
          <label className="label">
            <span className="label-text">Email</span>
          </label>
          <input
            id="email-input"
            type="text"
            placeholder="email@me.com"
            className="input input-bordered w-full max-w-xs"
            value={email}
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Password</span>
          </label>
          <input
            id='password-input'
            type="password"
            placeholder="Secret password"
            className="input input-bordered w-full max-w-xs"
            value={password}
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="flex items-center justify-between gap-3">
          <label className="flex cursor-pointer gap-3 text-xs">
            Remember me
          </label>
        </div>
        <button id='login-button' className="btn btn-neutral" type="submit">
          Login
        </button>
      </form>
    </div>
  );
};

export default ManualLoginView;
