import { CgNotes } from "react-icons/cg";
import { PageButton } from "@components/PageButton";
import { MdFeedback } from "react-icons/md";
import letsEatImage from "/src/assets/lets-eat.png";
import { useNavigate } from "react-router-dom";
import { FaQuestion } from "react-icons/fa";
import { SiSololearn } from "react-icons/si";
import { useApi } from "@api/api";
import { useEffect } from "react";

const HomeView = () => {
  const navigate = useNavigate();
  const { useProfileQuery } = useApi();
  const { data: profile, isLoading } = useProfileQuery();

  useEffect(() => {
    const hasOnboarded = isLoading ? true : profile.has_on_boarded;
    if (!hasOnboarded) {
      navigate("/on-boarding");
    }
  }, [profile]);

  return (
    <div className="p-6 space-y-4 max-w-xl w-full text-xl font-bold">
      <div className="block text-center font-bold w-full">MealBot Home</div>
      <LetsEatButton />
      <div className="grid grid-cols-2 gap-4">
        <div
          className="btn h-32 btn-block text-xl font-bold items-center"
          onClick={() => navigate("/faq")}
        >
          FAQ
          <FaQuestion className="text-4xl" />
        </div>
        <div className="btn h-32 btn-block text-xl font-bold items-center">
          About <SiSololearn className="text-4xl" />
        </div>
        <a
          href="https://feedback.mealbot.app/"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-block h-32 flex items-center font-bold text-xl"
        >
          Give Feedback <MdFeedback className="text-4xl" />
        </a>
        <ReleaseNotesButton />
      </div>
    </div>
  );
};

export default HomeView;

const ReleaseNotesButton = () => (
  <PageButton
    path="/release-notes"
    label="Release Notes"
    icon={CgNotes}
    className="h-32"
  />
);

const LetsEatButton = () => {
  const navigate = useNavigate();
  return (
    <div
      className="btn btn-circle w-full h-64 relative"
      onClick={() => navigate("/meal/new")}
    >
      <img
        src={letsEatImage}
        alt="Cooking Robot"
        className="w-full h-full object-cover opacity-40 btn-circle"
      />
      <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-6xl">
        Lets Cook!
      </p>
    </div>
  );
};
