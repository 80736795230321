import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import ReactDOM from "react-dom/client";
import router from "./router.jsx";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthContextProvider } from "@contexts/AuthContext.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "react-hot-toast";
import { registerSW } from "virtual:pwa-register";
import CookieConsent from "react-cookie-consent";
import { gtag, initDataLayer, install } from "ga-gtag";
import * as Sentry from "@sentry/react";
import {
  isLocalhost,
  needsGoogleAnalytics,
  sentryEnvName,
} from "@components/util";

const queryClient = new QueryClient();
if (!isLocalhost)
  Sentry.init({
    dsn: "https://1417e66fa3f6c25894cf6f96bde301b8@o4506630016860160.ingest.sentry.io/4506630022823936",
    environment: sentryEnvName,
  });

registerSW({ immediate: true });
if (needsGoogleAnalytics) {
  initDataLayer();
  gtag("consent", "default", {
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
  });
  install("G-FKFYVG7DYS");
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    {needsGoogleAnalytics && (
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="mealbotCookieConsent"
        onAccept={() => {
          gtag("consent", "update", {
            analytics_storage: "granted",
          });
        }}
      >
        Blending cookies with code, not just in the kitchen! Accept to help us
        bake an app experience as delightful as dessert.
      </CookieConsent>
    )}
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={window._env_.GOOGLE_CLIENT_ID}>
        <AuthContextProvider>
          <Toaster />
          <RouterProvider router={router} />
          {isLocalhost && <ReactQueryDevtools initialIsOpen={false} />}
        </AuthContextProvider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
