import React from "react";

type TimeSelectorProps = {
  selectedTime: number;
  setSelectedTime: React.Dispatch<React.SetStateAction<number>>;
  size?: "small" | "large";
};

const TimeSelector: React.FC<TimeSelectorProps> = ({
  selectedTime,
  setSelectedTime,
  size = "large",
}) => {
  const times = [
    { minutes: 0, label: "Im hangry!" },
    { minutes: 30, label: "30 minutes" },
    { minutes: 60, label: "1 hour" },
    { minutes: 120, label: "2 hours" },
    { minutes: 999, label: "Whenever..." },
  ];

  const handleSelect = (minutes: number) => {
    setSelectedTime(minutes);
  };

  const sizeClasses =
    size === "small" ? "btn-sm text-sm font-light" : "btn-lg text-3xl";

  return (
    <div className="flex flex-wrap items-center gap-2 justify-center">
      {times.map((time) => (
        <button
          key={time.minutes}
          className={`btn ${sizeClasses} ${
            selectedTime === time.minutes ? "btn-primary" : ""
          }`}
          onClick={(e) => {
            e.preventDefault();
            handleSelect(time.minutes);
          }}
        >
          {time.label}
        </button>
      ))}
    </div>
  );
};

export default TimeSelector;
