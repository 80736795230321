import React from "react";
import { Link } from "react-router-dom";
import ThankYouRobot from "@assets/thank-you-robot.svg";

const ThankYouView: React.FC = () => {
  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content text-center">
        <div className="max-w-md">
          <div className="card bg-base-100 shadow-xl">
            <div className="card-body items-center text-center">
              <h2 className="card-title text-2xl mb-4">Welcome to Mealbot!</h2>
              <img src={ThankYouRobot} alt="Thank you robot" />
              <p className="m-4">
                Let's spark some kitchen magic! With each ingredient, a new
                recipe awaits. Curious what we'll create together?
              </p>
              <div className="card-actions justify-end">
                <Link to="/meal/new" className="btn btn-primary">
                  Let's get cooking!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouView;
