import React from "react";
import { Outlet } from "react-router-dom";

import Navbar from "@components/Navigation/Navbar";
import { NavigationProvider } from "@contexts/NavigationContext";

const LoggedInLayout: React.FC = () => {
  return (
    <NavigationProvider>
      <div className="flex flex-col max-w-xl mx-auto">
        <div className="mb-14">
          <Outlet />
        </div>
        <Navbar />
      </div>
    </NavigationProvider>
  );
};

export default LoggedInLayout;
