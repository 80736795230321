import { useEffect, useState, useMemo } from "react";
import { useAdminApi } from "@api/adminApi";
import ShortLoading from "@components/ShortLoading";
import { Table, Button } from "react-daisyui";
import SortableOption from "@components/Admin/SortableOption";
import { sortData, options } from "@components/Admin/util";
import AIInteractionModal from "@components/AIInteractionModal";
import TimestampFormatter from "@components/TimeStampFormatter";
import SearchBar from "@components/SearchBar";

const FeedbackSection = () => {
    const [feedbackSortConfig, setFeedbackSortConfig] = useState({
      key: "timestamp",
      direction: "desc",
    });
    const [filters, setFilters] = useState(options);
    const [currentAii, setCurrentAii] = useState('');

    const { useFeedbackQuery, archiveFeedback } = useAdminApi();

    const feedbackQuery = useFeedbackQuery(
        filters.maxFeedback.value, 
        filters.includeArchived.value === 'true', 
        filters.sentiment.value,
        filters.search.value
    );
    const { data: feedback, isLoading } = feedbackQuery;

    const sortedAndFilteredFeedback = useMemo(() => {
        const sortedUsers = sortData(
          feedback || [],
          feedbackSortConfig.key,
          feedbackSortConfig.direction,
        );
        return sortedUsers.slice(0, filters.maxFeedback.value);
      }, [feedback, feedbackSortConfig, filters.maxFeedback.value]);

    const handleSortChange = (key, direction) => {
        setFeedbackSortConfig({ key, direction });
    }

    const changeFilter = (filterName, newValue) => {
        setFilters({
          ...filters,
          [filterName]: { ...filters[filterName], value: newValue },
        });
      };

    useEffect(() => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          feedbackSortBy: {
            ...prevFilters.feedbackSortBy,
            value: prevFilters.feedbackSortBy.value,
          },
        }));
      }, [feedback, filters.feedbackSortBy.value]);

    console.log(feedback);

    return (
        <>
        <div className="flex items-center align-middle">
            <div className="mr-2 flex items-center align-middle">
                <label className="label flex items-center align-middle">
                    <div className="m-2">Max Results</div>
                </label>
                <select
                    className="select select-bordered w-28 select-sm"
                    onChange={(e) => changeFilter("maxFeedback", parseInt(e.target.value))}
                    value={filters.maxFeedback.value}
                >
                    <option value={1}>1</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={1000}>1000</option>
                </select>
            </div>
            <div className="mr-2 flex items-center align-middle">
                <label className="label flex items-center align-middle">
                    <div className="m-2">Include Archived</div>
                </label>
                <select
                    className="select select-bordered w-28 select-sm"
                    onChange={(e) => changeFilter("includeArchived", e.target.value)}
                    value={filters.includeArchived.value}
                >
                    <option value={"true"}>Yes</option>
                    <option value={"false"}>No</option>
                </select>
            </div>
            <div className="mr-2 flex items-center align-middle">
                <label className="label flex items-center align-middle">
                    <div className="m-2">Sentiment</div>
                </label>
                <select
                    className="select select-bordered w-28 select-sm"
                    onChange={(e) => changeFilter("sentiment", e.target.value)}
                    value={filters.sentiment.value}
                >
                    <option value={"all"}>All</option>
                    <option value={"thumbs_up"}>👍</option>
                    <option value={"thumbs_down"}>👎</option>
                </select>
            </div>
            <div className="mr-2 flex items-center align-middle ml-auto">
                <SearchBar onSearch={(search) => changeFilter("search", search)} />
            </div>
        </div>
        <hr className="my-4" />
        {!isLoading && (
            <Table>
                <Table.Head>
                <SortableOption
                    title="Email"
                    id="user"
                    onSortChange={handleSortChange}
                    activeSortKey={feedbackSortConfig.key}
                />
                <SortableOption
                    title="Sentiment"
                    id="kind"
                    onSortChange={handleSortChange}
                    activeSortKey={feedbackSortConfig.key}
                />
                <SortableOption
                    title="Message"
                    id="response"
                    onSortChange={handleSortChange}
                    activeSortKey={feedbackSortConfig.key}
                />
                <SortableOption
                    title="Timestamp"
                    id="timestamp"
                    onSortChange={handleSortChange}
                    activeSortKey={feedbackSortConfig.key}
                />
                <span>
                    Options
                </span>
                </Table.Head>
                <Table.Body>
                    {sortedAndFilteredFeedback.map((feedback) => (
                        <Table.Row key={feedback._id}>
                        <span>{feedback.user}</span>
                        <span className="text-xl">{feedback.kind == 'thumbs_down' ? '👎' : '👍'}</span>
                        <span>{feedback.response}</span>
                        <span><TimestampFormatter timestamp={feedback.timestamp} /></span>
                        <span>
                            <Button 
                                size="sm" 
                                color="primary"
                                onClick={() => {
                                    setCurrentAii(feedback.aii_id);
                                }}
                            >
                                View AII
                            </Button>
                            {!feedback.archived && (
                                <Button
                                    size="sm"
                                    color="error"
                                    className="ml-2"
                                    onClick={() => archiveFeedback.mutate(feedback._id)}
                                >
                                    Archive
                                </Button>
                            )}
                            {feedback.archived && (
                                <Button
                                    size="sm"
                                    color="success"
                                    className="ml-2"
                                    onClick={() => archiveFeedback.mutate(feedback._id)}
                                >
                                    Unarchive
                                </Button>
                            )}
                        </span>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        )}
        {isLoading && <ShortLoading />}
        {currentAii !== '' && (
            <AIInteractionModal 
                currentAIIID={currentAii}
                isOpen={currentAii !== ''}
                onClose={() => setCurrentAii('')}
            />
        )}
        </>
    );
};

export default FeedbackSection;
