import React from "react";

type ApplianceSelectorProps = {
  selectedAppliances: string[];
  setSelectedAppliances: React.Dispatch<React.SetStateAction<string[]>>;
  size?: "small" | "large";
};

const ApplianceSelector: React.FC<ApplianceSelectorProps> = ({
  selectedAppliances,
  setSelectedAppliances,
  size = "small",
}) => {
  const appliances: string[] = [
    "Oven",
    "Microwave",
    "Stove",
    "Grill",
    "Slow Cooker",
    "Air Fryer",
    "Pressure Cooker",
  ];

  const isNothingSelected = selectedAppliances.length === 0;

  const handleSelect = (appliance: string) => {
    if (appliance === "Everything") {
      setSelectedAppliances([]);
    } else {
      if (selectedAppliances.includes(appliance)) {
        setSelectedAppliances(
          selectedAppliances.filter((item) => item !== appliance),
        );
      } else {
        const next = [...selectedAppliances, appliance];
        if (next.length === appliances.length) {
          setSelectedAppliances([]);
        } else {
          setSelectedAppliances(next);
        }
      }
    }
  };

  const sizeClasses =
    size === "small"
      ? "btn-sm text-lg font-light"
      : "btn-lg text-3xl font-bold";

  return (
    <div>
      <div className="flex flex-wrap gap-2 items-center justify-center">
        <button
          className={`btn ${sizeClasses} ${
            isNothingSelected ? "btn-primary" : "btn-outline"
          }`}
          onClick={() => handleSelect("Everything")}
        >
          Everything
        </button>
        {appliances.map((appliance) => (
          <button
            key={appliance}
            className={`btn ${sizeClasses} ${
              selectedAppliances.includes(appliance) ? "btn-primary" : ""
            }`}
            onClick={() => handleSelect(appliance)}
          >
            {appliance}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ApplianceSelector;
