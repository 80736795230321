import React, { useState } from "react";
import { MealCreationFeedbackRequest } from "@api/api";

type FeedbackModalProps = {
  aii_id: string;
  onSubmit: (feedback: MealCreationFeedbackRequest) => void;
  onCancel: () => void;
};

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  aii_id,
  onSubmit,
  onCancel,
}) => {
  const [response, setResponse] = useState("");

  const submitFeedback = (kind: string) => {
    onSubmit({
      kind,
      aii_id,
      response,
    });
  };

  return (
    <div className="modal modal-open">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Let us know whats up</h3>
        <textarea
          className="textarea textarea-bordered w-full h-24 mt-4"
          placeholder="Enter anything you like..."
          value={response}
          onChange={(e) => setResponse(e.target.value)}
        ></textarea>
        <div className="modal-action">
          <button
            className="btn btn-success"
            onClick={() => submitFeedback("thumbs_up")}
          >
            👍
          </button>
          <button
            className="btn btn-error"
            onClick={() => submitFeedback("thumbs_down")}
          >
            👎
          </button>
          <button className="btn btn-error btn-outline" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
