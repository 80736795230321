import PropTypes from 'prop-types';

const TimestampFormatter = ({ timestamp }) => {
  const date = new Date(timestamp);
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short'
  }).format(date);

  return <span>{formattedDate}</span>;
};

TimestampFormatter.propTypes = {
  timestamp: PropTypes.string.isRequired,
};

export default TimestampFormatter;